import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { InnerBannerImg } from "../../constant/Index";
import { fetchProducts } from "../../store/productSlice";

const ChildCategory = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const subcat = useSelector((state) => state.product?.SubCategory);

	const handleSub = (id, e) => {
		e.preventDefault();
		dispatch(fetchProducts(id));
		navigate(`/shop`);
	};
	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			{/* Inner Banner */}
			<section className="universal_banner">
				<div className="container-fluid p-0">
					<div className="banner-content">
						<div className="banner_image">
							<figure>
								<img src={InnerBannerImg} className="img-fluid" />
							</figure>
						</div>
						<div className="page-title">
							<h2 className="title">Child Categories</h2>
						</div>
					</div>
				</div>
			</section>
			{/* Inner Banner */}

			<section className="category_box py-5 text-center">
				<div className="container">
					<div className="row">
						{subcat?.map((catePro, index) => {
							return (
								<div className="col-lg-3 col-md-4" key={index}>
									<button
										type="button"
										onClick={(e) => handleSub(catePro?.id, e)}
									>
										<div className="cat-card">
											<div className="title">
												<h5>{catePro?.name}</h5>
											</div>
										</div>
									</button>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			{/* Header */}
			<Footer />
			{/* Header */}
		</>
	);
};

export default ChildCategory;
