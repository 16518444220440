import React, { useEffect, useState } from "react";
import "../../assets/css/profile.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { ProfileImg } from "../../constant/Index";
import BasicInfo from "./BasicInfo";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import ChangeAddress from "./ChangeAddress";
import MyOrders from "./MyOrders";
import MyWishlist from "./MyWishlist";
import { logout, reset } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { fetchWishlist } from "../../store/productSlice";

const Profile = () => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchWishlist());
	}, []);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const [portion, setPortion] = useState(1);
	const ChangePortin = (e) => {
		setPortion(e);
	};

	let navigate = useNavigate();

	const handleRemoveToken = () => {
		confirmAlert({
			title: "Confirm to logout",
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						dispatch(logout());
						dispatch(reset());
						navigate("/signin");
					},
				},
				{
					label: "No",
					onClick: () => {},
				},
			],
		});
	};

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Profile Sec */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="profile-sidebar-wrapper">
								<div className="profile-headin-wrapper text-center">
									<h3>My Accounts</h3>
								</div>
								<div className="profile-img-wrapper text-center">
									<figure>
										<img src={user?.image} className="img-fluid" alt="" />
									</figure>
									<div className="profile-content-wrapper">
										<h6>{user.f_name}</h6>
										<h6>{user.email}</h6>
									</div>
								</div>
								<div
									className="flex-column nav-pills"
									id="v-pills-tab"
									role="tablist"
									aria-orientation="vertical"
								>
									<ul className="nav md-pills pills-secondary d-flex flex-column">
										<li className="nav-item">
											<a
												className="nav-link active"
												data-toggle="tab"
												href="#panel11"
												role="tab"
											>
												My Profile
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												data-toggle="tab"
												href="#panel12"
												role="tab"
											>
												My Orders
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												data-toggle="tab"
												href="#panel13"
												role="tab"
											>
												My Wishlist
											</a>
										</li>
										<li className="nav-item">
											<button onClick={handleRemoveToken} className="nav-link">
												Log Out
											</button>
											{/* <Link to="/" className="nav-link">
                        Log Out
                      </Link> */}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-9">
							<div className="profile-tabs-cotent-wrapper">
								<div className="tab-content pt-0">
									<div
										className="tab-pane fade in show active"
										id="panel11"
										role="tabpanel"
									>
										<div className="profile-tabe-wrapper">
											{portion === 1 ? (
												<BasicInfo changePortion={ChangePortin} />
											) : (
												<></>
											)}
											{portion === 2 ? (
												<EditProfile changePortion={ChangePortin} />
											) : (
												<></>
											)}
											{portion === 3 ? (
												<ChangePassword changePortion={ChangePortin} />
											) : (
												<></>
											)}
											{portion === 4 ? (
												<ChangeAddress changePortion={ChangePortin} />
											) : (
												<></>
											)}
										</div>
									</div>

									<div className="tab-pane fade" id="panel12" role="tabpanel">
										{/* <!-- my order starts here --> */}
										<MyOrders />
										{/* <!-- my order ends here --> */}
										{/* <!-- Modal starts here--> */}

										{/* <!-- Modal ends here--> */}
									</div>

									<div className="tab-pane fade" id="panel13" role="tabpanel">
										{/* <!-- my order starts here --> */}
										<MyWishlist />
										{/* <!-- my order ends here --> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Profile Sec */}

			{/* Header Sec */}
			<Footer />
			{/* Header Sec */}
		</>
	);
};

export default Profile;
