import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeProfilePass } from "../../store/auth/authSlice";

const ChangePassword = (props) => {
  const [passCurrentView, setPassCurrentView] = useState(true);
  const [passNewView, setpassNewView] = useState(true);
  const [passConfirmView, setpassConfirmView] = useState(true);
  const ShowPassword1 = () => {
    if (passCurrentView) {
      setPassCurrentView(false);
    } else {
      setPassCurrentView(true);
    }
  };
  const ShowPassword2 = () => {
    if (passNewView) {
      setpassNewView(false);
    } else {
      setpassNewView(true);
    }
  };
  const ShowPassword3 = () => {
    if (passConfirmView) {
      setpassConfirmView(false);
    } else {
      setpassConfirmView(true);
    }
  };
  const { user } = useSelector((state) => state.auth);
  const [current_password, setCurrentpass] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPass] = useState("");
  const dispatch = useDispatch();

  const formData = new FormData();
  formData.set("identity", user?.email);
  formData.set("current_password", current_password);
  formData.set("password", password);
  formData.set("confirm_password", confirm_password);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(changeProfilePass(formData));
  };
  return (
    <>
      <div className="pro-edit-password-wrapper">
        <div className="profile-content-heading">
          <h4>Change Password</h4>
        </div>
        <div className="profile-info-box-wrapper">
          <div className="box-fields-wrapper edit-fields">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-6">
                  <div className="form-group-input">
                    <input
                      value={current_password}
                      onChange={(e) => setCurrentpass(e.target.value)}
                      type={passCurrentView ? "password" : "text"}
                      className="form-control"
                      placeholder="Current Password"
                    />
                    <button type="button" onClick={ShowPassword1}>
                      {passCurrentView ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group-input">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={passNewView ? "password" : "text"}
                      placeholder="New Password"
                      className="form-control"
                    />
                    <button type="button" onClick={ShowPassword2}>
                      {passNewView ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <div className="form-group-input">
                    <input
                      value={confirm_password}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      type={passConfirmView ? "password" : "text"}
                      placeholder="Confirm Password "
                      className="form-control"
                    />
                    <button type="button" onClick={ShowPassword3}>
                      {passConfirmView ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 text-right">
                  <button
                    onClick={() => {
                      props.changePortion(1);
                    }}
                    type="button"
                    className="btn mr-2"
                  >
                    Back
                  </button>
                  <button type="submit" className="btn">
                    Save
                  </button>
                </div>
                <div className="col-lg-6 text-right"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
