import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchFaqs } from "../../store/Faqs/faqsSlice";

const Faqs = (props) => {
  const faqs = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFaqs());
  }, []);
  return (
    <>
      {faqs?.faqData?.map((faqs, index) =>
        index < (props.faqlimit ? props.faqlimit : faqs.length) ? (
          <div key={index} className="card">
            <div className="card-header" id={faqs?.id}>
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target={"#collapse" + faqs?.id}
                  aria-expanded="true"
                  aria-controls={"collapse" + faqs?.id}
                >
                  <span>{faqs?.question}</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </h5>
            </div>

            <div
              id={"collapse" + faqs?.id}
              className={faqs.id != 1 ? "collapse" : "collapse show"}
              aria-labelledby={faqs?.id}
              data-parent="#accordion"
            >
              <div className="card-body">
                <p>{faqs?.answer}</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      )}
    </>
  );
};

export default Faqs;
