import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddress, setAddressId } from "../../store/productSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";

const SaveAddress = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { GetAddress } = useSelector((state) => state.product);

	const handleClick = (id) => {
		dispatch(setAddressId(id));
	};

	const addClear = {
		position: "absolute",
		right: "22px",
		bottom: "20px",
		color: "red",
		zIndex: "111",
	};

	const HandleDelete = (id) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			let Url = `https://nutirents.developer-ourbase-camp.com/api/v1/customer/address?address_id=${id}`;
			axios.delete(Url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			dispatch(getAddress());
			toast.success("Address Deleted Successfully");
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			<label className="container">
				<input
					type="radio"
					name="radio"
					onClick={() => handleClick(user?.id)}
				/>
				<span className="checkmark">
					<div className="select-address">
						<div className="selection">
							<i className="fa fa-check" aria-hidden="true"></i>
						</div>
						<div className="radio-btn">
							<span></span>
						</div>
						<div className="address-detail">
							<h5>
								<span className="property">Country:</span>
								<span className="value">{user?.country}</span>
								<span className="property pl-4">State:</span>
								<span className="value">{user?.state}</span>
							</h5>
							<h5>
								<span className="property">Address:</span>
								<span className="value">{user?.street_address}</span>
							</h5>
							<h5>
								<span className="property">Phone:</span>
								<span className="value">{user?.phone}</span>
							</h5>
						</div>
					</div>
				</span>
			</label>
			{GetAddress &&
				GetAddress.map((item, index) => {
					return (
						<>
							<label className="container" style={{ position: "relative" }}>
								<input
									type="radio"
									name="radio"
									onClick={() => handleClick(item.id)}
								/>
								<DeleteIcon
									style={addClear}
									onClick={() => HandleDelete(item.id)}
								/>
								<span className="checkmark">
									<div className="select-address">
										<div className="selection">
											<i className="fa fa-check" aria-hidden="true"></i>
										</div>
										<div className="radio-btn">
											<span></span>
										</div>
										<div className="address-detail">
											<h5>
												<span className="property">Country:</span>
												<span className="value">{item && item?.country}</span>
												<span className="property pl-4">State:</span>
												<span className="value">{item && item?.state}</span>
											</h5>
											<h5>
												<span className="property">Address:</span>
												<span className="value">{item && item?.address}</span>
											</h5>
											<h5>
												<span className="property">Phone:</span>
												<span className="value">{item && item?.phone}</span>
											</h5>
										</div>
									</div>
								</span>
							</label>
						</>
					);
				})}
		</>
	);
};

export default SaveAddress;
