import React, { useEffect } from "react";
import { WishlistImg } from "../../constant/Index";
import { deleteWishlist, fetchWishlist } from "../../store/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../store/cartSlice";
import { toast } from "react-toastify";

const MyWishlist = () => {
	const dispatch = useDispatch();
	const wishlist = useSelector((state) => state.product?.WishlistProduct);
	const handleAdd = (wishlist) => {
		dispatch(add(wishlist));
	};

	const wishlistRemovehandle = (product_id) => {
		dispatch(deleteWishlist(product_id));
	};
	return (
		<>
			<div className="profile-tabe-wrapper">
				<div className="pro-detail-wrapper">
					<div className="profile-content-heading">
						<h4>My wishlist</h4>
					</div>
					<div className="all-wishlist">
						{wishlist?.length > 0 ? (
							wishlist &&
							Object.values(wishlist)?.map((wishlist, index) => (
								<div key={index} className="card-wrapper">
									<button
										onClick={() => wishlistRemovehandle(wishlist?.product_id)}
										className="close"
									>
										<i style={{ color: "#1AAC7A" }} className="fa fa-close"></i>
									</button>
									<div className="pro-content-wrapper">
										<div className="img-wrapper">
											<figure>
												<img
													src={wishlist?.product?.thumbnail}
													className="img-fluid"
													alt=""
												/>
											</figure>
										</div>
										<div className="pro-content">
											<h5 className="wishlist-name">
												{wishlist?.product?.name}
											</h5>
											<h5 className="price">
												£{wishlist?.product?.unit_price}
											</h5>
											<h6 className="stocks">
												{wishlist?.product?.current_stock < 1 ? (
													<>
														<span style={{ color: "red" }}>Out of stock</span>
													</>
												) : (
													<>
														In Stock :{" "}
														<span style={{ color: "#1AAC7A" }}></span> (
														{wishlist?.product?.current_stock})
													</>
												)}
											</h6>
										</div>
									</div>
									{wishlist?.product?.current_stock < 1 ? (
										<>
											<button
												onClick={() =>
													toast.error("Sorry this product is not available")
												}
												className="btn"
											>
												Out of stock
											</button>
										</>
									) : (
										<>
											<button
												onClick={() => handleAdd(wishlist?.product)}
												className="btn"
											>
												Add to cart
											</button>
										</>
									)}
								</div>
							))
						) : (
							<div className="mt-5">
								<h4>No wishlist found</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MyWishlist;
