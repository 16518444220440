import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { resetProfile } from "./authSlice";

let token = localStorage.getItem("Token");
const API_URL = "https://nutirents.developer-ourbase-camp.com/api/v1";

// Signup User
const register = async (userData) => {
	try {
		const response = await axios.post(API_URL + "/auth/register", userData);

		if (response.data) {
			toast.success("User Registered Successfully");
		}
		return response.data;
	} catch (err) {
		if (err.message == "Request failed with status code 400") {
			toast.error("Email or Phone is already exist!");
		} else {
			toast.error(err.message);
		}
		return err.message;
	}
};

// Signin User
const login = async (userData) => {
	try {
		const response = await axios.post(API_URL + "/auth/login", userData);
		localStorage.setItem(
			"Token",
			JSON.stringify(response?.data?.response?.data?.token),
		);
		localStorage.setItem(
			"UserData",
			JSON.stringify(response?.data?.response?.data?.user),
		);
		toast.success("Login Successfully");

		return response.data;
	} catch (err) {
		if (err.message == "Request failed with status code 400") {
			toast.error(err.response?.data?.errors[0]?.message);
		} else {
			toast.error(err.message);
		}
		return err.message;
	}
};

// forgot User
const forgot = async (identity) => {
	try {
		const response = await axios.post(
			API_URL + "/auth/forgot-password",
			identity,
		);
		toast.success("Otp Send Successfully");
		return response;
	} catch (err) {
		if (err.message == "Request failed with status code 400") {
			toast.error(err.response?.data?.errors[0]?.message);
		} else {
			toast.error(err.message);
		}
		return err.message;
	}
};

const otp = async (otp) => {
	try {
		const response = await axios.post(API_URL + "/auth/verify-otp", otp);
		toast.success(response?.data?.message);
		return response;
	} catch (err) {
		if (err.message == "Request failed with status code 400") {
			toast.error("Invalid Otp");
		} else {
			toast.error(err?.message);
		}
		return err?.message;
	}
};

const changePass = async (data) => {
	try {
		const response = await axios.post(API_URL + "/auth/reset-password", data);
		toast.success(response?.data?.message);
		return response;
	} catch (err) {
		if (err.message == "Request failed with status code 400") {
			toast.error("Password & ConfirmPassword are not same!");
		} else {
			toast.error(err?.message);
		}
		return err?.message;
	}
};

const logout = () => {
	localStorage.clear();
	sessionStorage.clear();
};

const editProfileRequest = (userData) => {
	return async (dispatch) => {
		try {
			let tokenz = localStorage.getItem("Token");
			if (tokenz) {
				tokenz = tokenz.slice(1, -1);
			}
			const response = await axios.post(
				API_URL + "/customer/update-profile",
				userData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${tokenz}`,
					},
				},
			);
			localStorage.setItem(
				"UserData",
				JSON.stringify(response?.data?.response?.data),
			);
			toast.success("Update Successfully");
			dispatch(resetProfile(response.data.response.data));
		} catch (err) {
			err.response.data.errors.forEach((err) => {
				toast.error(err);
			});
		}
	};
};

const profilePass = async (updatePass) => {
	try {
		const response = await axios.post(
			API_URL + "/auth/change-password",
			updatePass,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token.slice(1, -1)}`,
				},
			},
		);
		toast.success(response?.data?.message);
		return response.data;
	} catch (err) {
		toast.error(err.response.data.errors.password[0]);
		return err?.message;
	}
};

const profileAddress = (userData) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				API_URL + "/customer/update-address",
				userData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token.slice(1, -1)}`,
					},
				},
			);
			localStorage.setItem(
				"UserData",
				JSON.stringify(response?.data?.response?.data),
			);
			toast.success("Update Successfully");
			dispatch(resetProfile(response.data.response.data));
		} catch (err) {
			if (err.message == "Request failed with status code 400") {
				toast.error(err.response?.data?.errors[0]?.message);
			} else {
				toast.error(err.message);
			}
		}
	};
};

const subscribeNewsletter = (email) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				API_URL + "/newsletter_subscribe",
				email,
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			);
			toast.success(response.data.response.data);
		} catch (err) {
			toast.error(err?.response?.data?.errors?.email[0]);
		}
	};
};

const Contact = (contactData) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				API_URL + "/contact_query",
				contactData,
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			);
			toast.success(response.data.response.data);
			return response.data;
		} catch (err) {
			if (
				err?.response?.data?.errors.subject &&
				err?.response?.data?.errors.phone
			) {
				toast.error("Subject field is required");
			} else if (err?.response?.data?.errors.subject) {
				toast.error(err?.response?.data?.errors?.subject[0]);
			} else if (err?.response?.data?.errors.phone) {
				toast.error(err?.response?.data?.errors?.phone[0]);
			}
		}
	};
};

const authService = {
	register,
	login,
	logout,
	forgot,
	otp,
	changePass,
	editProfileRequest,
	profilePass,
	profileAddress,
	subscribeNewsletter,
	Contact,
};

export default authService;
