import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../assets/css/signup.css";
import "../../assets/css/signin.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
	AuthImg,
	FacebookImg,
	GoogleImg,
	TwitterImg,
} from "../../constant/Index";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../../store/auth/authSlice";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";

const Signin = () => {
	const [passView, setPassView] = useState(true);
	const ShowPassword = () => {
		if (passView) {
			setPassView(false);
		} else {
			setPassView(true);
		}
	};
	const [formData, setformData] = useState({
		email: "",
		password: "",
	});

	useEffect(() => {
		getcookiedata();
	}, []);

	const getcookiedata = () => {
		let Eml = getCookies("email");
		let PaS = getCookies("password");
		if (Eml && PaS) {
			setformData({
				email: Eml,
				password: PaS,
			});
		}
	};

	const getCookies = (cemail) => {
		let email = cemail + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(email) === 0) {
				return c.substring(email.length, c.length);
			}
		}
		return "";
	};

	const { email, password } = formData;

	const HandleRemember = () => {
		let emi = email;
		let pass = password;
		document.cookie = `email=${emi};path=http://localhost:3000/signin`;
		document.cookie = `password=${pass};path=http://localhost:3000/signin`;
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user, isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth,
	);

	useEffect(() => {
		// if (isError) {
		// 	toast.error(message);
		// }

		if (isSuccess) {
			navigate("/profile");
		}

		dispatch(reset());
	}, [user, isError, isSuccess, message, navigate, dispatch]);

	const onChange = (e) => {
		setformData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmit = (e) => {
		e.preventDefault();

		if (password === "") {
			toast.error("Please enter password");
		} else if (email === "") {
			toast.error("Please enter email");
		} else {
			const userData = {
				email,
				password,
			};
			dispatch(login(userData));
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<>
			{/* Banner Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			<section className="register-user">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="register-img">
								<img src={AuthImg} className="img-fluid" alt="" />
								<div className="text-on-img text-center text-white">
									<h3 className="mb-4 font-weight-normal">
										Welcome to Nutritions24
									</h3>
									<p className="mb-4">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore.
									</p>
									<div className="get-connected mb-4">
										<span>Get Connected With</span>
									</div>
									<div className="social-icons mb-5">
										<ul className="row justify-content-center">
											<li>
												<Link to="#">
													<img
														className="img-fluid twitter"
														src={TwitterImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid facebook"
														src={FacebookImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid google"
														src={GoogleImg}
														alt=""
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="auth-btn text-right">
								<Link to="/signin" className="btn mr-2">
									Sign in
								</Link>
								<Link to="/signup" className="btn light-btn">
									Sign up
								</Link>
							</div>
							<div className="signup-form">
								<h2 className="mb-4">Sign In</h2>
								<p className="mb-4">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore.
								</p>
								<hr />
								<form
									method="POST"
									onSubmit={onSubmit}
									// onSubmit={(e) => handlesubmit(e)}
								>
									<div className="row mt-4">
										<div className="col-md-12 mt-4">
											<label htmlFor="">Email Address</label>
											<input
												required
												type="email"
												name="email"
												value={email}
												onChange={onChange}
												className="form-control"
												placeholder="johndoe@gmail.com"
											/>
										</div>
										<div className="col-md-12 mt-4">
											<label htmlFor="">Password</label>
											<div className="form-group-input">
												<input
													required
													name="password"
													value={password}
													onChange={onChange}
													type={passView ? "Password" : "Text"}
													className="form-control"
													placeholder="12345678"
												/>
												<button type="button" onClick={ShowPassword}>
													{passView ? (
														<i
															className="fa fa-eye-slash"
															aria-hidden="true"
														></i>
													) : (
														<i className="fa fa-eye" aria-hidden="true"></i>
													)}
												</button>
											</div>
										</div>
										<div className="col-md-6 mt-2">
											<input
												type="checkbox"
												id="remember"
												onClick={HandleRemember}
											/>
											<label htmlFor="remember">Remember Me</label>
										</div>
										<div className="col-md-6 text-right mt-2">
											<Link className="forgot-password" to="/forgot-password">
												Forget Password
											</Link>
										</div>
										<div className="col-md-12 mt-5">
											<button type="submit" className="btn w-100">
												Sign in
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Signin;
