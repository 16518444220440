import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/product-detail.css";
import Slider from "react-slick";
import {
	ProductDetailImg,
	productSliderImg,
	relatedproduct1,
	relatedproduct2,
	relatedproduct3,
	relatedproduct4,
} from "../../constant/Index";
import {
	fetchProductDetail,
	fetchRelatedProduct,
	STATUSES,
	AddWishlist,
	AddCart,
	AddCartGetAll,
} from "../../store/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { add, decreasedCart } from "../../store/cartSlice";
import RelatedProduct from "../../components/RelatedProduct/RelatedProduct";
import ProductReview from "../../components/ProductReview/ProductReview";
import AddReview from "../../components/ProductReview/AddReview";
import Spinner from "../../components/Spinner/Spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import currency from "currency-formatter";

const ProductDetail = () => {
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();
	const navigate = useNavigate();
	var ProductSlider = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const ProductImages = [
		{
			id: 1,
			image: productSliderImg,
		},
		{
			id: 2,
			image: productSliderImg,
		},
		{
			id: 3,
			image: productSliderImg,
		},
		{
			id: 4,
			image: productSliderImg,
		},
		{
			id: 5,
			image: productSliderImg,
		},
		{
			id: 6,
			image: productSliderImg,
		},
		{
			id: 7,
			image: productSliderImg,
		},
	];
	let { id } = useParams();

	const ProductDetail = useSelector((state) => state.product);

	const { isSuccess } = useSelector((state) => state.product);

	const [loader, setLoader] = useState(false);

	const [changer, setChanger] = useState();

	const { Cart, AddData } = useSelector((state) => state.product);

	const ImageChanger = (img) => {
		setChanger(img);
	};

	let dispatch = useDispatch();

	const ProData = ProductDetail.ProductDetail;

	const [quantity, setQuantity] = useState(1);

	const get = async () => {
		setLoader(true);
		await dispatch(fetchProductDetail(id));
		setLoader(false);
	};

	useEffect(() => {
		dispatch(AddCartGetAll());
	}, [AddData]);

	useEffect(() => {
		get();
		// dispatch(fetchRelatedProduct(id));
	}, [id]);

	const handleDecreasedQuantity = () => {
		if (1 >= quantity) return;

		const qty = quantity - 1;
		setQuantity(qty);
	};

	const addToCartHandler = (id) => {
		let checkItemAlreadyExist = Cart?.filter((val) => val?.product_id === id);
		if (checkItemAlreadyExist?.length > 0) {
			toast.error("Item Already Exist in Cart!!!");
		} else {
			const formData = new FormData();
			formData.append("id", id);
			formData.append("quantity", quantity);
			dispatch(AddCart(formData));
		}
	};

	const handleIncrementQuantity = () => {
		if (ProductDetail?.ProductDetail?.current_stock <= quantity) return;

		const qty = quantity + 1;
		setQuantity(qty);
	};

	const handleWishlist = () => {
		let form_data = new FormData();
		form_data.set("product_id", id);
		dispatch(AddWishlist(form_data));

		if (STATUSES == "idle") {
			navigate("/profile");
		}
	};
	if (loader) {
		return <Spinner />;
	}

	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			{/* Universal Banner */}
			<InnerBanner />
			{/* Universal Banner */}

			{/* Product Detail Sec Start Here */}
			<section className="product-details">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="product-detail-img-wrapper">
								<Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
									<figure>
										<img
											src={
												changer
													? changer
													: ProductDetail?.ProductDetail?.thumbnail
											}
											className="img-fluid"
											alt=""
										/>
									</figure>
								</Slider>
							</div>
							<div className="product-img-slider-wrapper">
								<div className="detailed-slider-img">
									<Slider
										asNavFor={nav1}
										ref={(slider2) => setNav2(slider2)}
										slidesToShow={2}
										swipeToSlide={true}
										focusOnSelect={true}
									>
										{ProductDetail?.ProductDetail?.imageurl?.map(
											(slider, index) => {
												return (
													<div
														key={index}
														className="img-wrapper"
														onClick={() => ImageChanger(slider)}
													>
														<img
															src={slider}
															style={{ cursor: "pointer" }}
															className="img-fluid"
															alt=""
														/>
													</div>
												);
											},
										)}
									</Slider>
								</div>
							</div>
						</div>
						<div className="col-lg-6 ">
							<div className="pl-5">
								<div className="product-name-wrapper">
									<h4>{ProductDetail?.ProductDetail?.name}</h4>
								</div>
								<div className="review-wrapper">
									<ul>
										{[...Array(ProductDetail?.ProductDetail?.rating)].map(
											(star, index) => {
												return (
													<li key={index}>
														<i className="fa fa-star"></i>
													</li>
												);
											},
										)}
										<li>
											<span className="total-reviews">
												( {ProductDetail?.ProductDetail?.reviews_count} reviews
												)
											</span>
										</li>
									</ul>
								</div>
								<div className="availability-wrapper">
									<span className="Availability">Availability :</span> &nbsp;
									&nbsp; &nbsp;{" "}
									<span className="InStock">
										{ProductDetail?.ProductDetail?.current_stock == 0 ? (
											"Out of stock"
										) : (
											<>
												In Stock ({ProductDetail?.ProductDetail?.current_stock})
											</>
										)}
									</span>
								</div>
								<div className="product-des-wrapper">
									<div
										className="product-des"
										dangerouslySetInnerHTML={{
											__html: ProductDetail?.ProductDetail?.details,
										}}
									/>
								</div>
								<div className="counter-and-price-wrapper">
									<div className="price-wrapper">
										<h2>
											<span className="sales-price mr-2">
												{
													currency
														.format(
															ProductDetail?.ProductDetail?.discountedprice !==
																0
																? Math.round(
																		ProductDetail?.ProductDetail
																			?.discountedprice,
																  )
																: ProductDetail?.ProductDetail?.unit_price,
															{
																code: "GBP",
															},
														)
														.split(".00")[0]
												}
												{/* £
												{ProductDetail?.ProductDetail?.discountedprice
													? Math.round(
															ProductDetail?.ProductDetail?.discountedprice,
													  )
													: ProductDetail?.ProductDetail?.unit_price} */}
											</span>
											<strike className="regular-price">
												{/* {ProductDetail?.ProductDetail?.discount == "0" ? (
													<></>
												) : (
													<>
														{
															currency
																.format(
																	ProductDetail?.ProductDetail?.discount,
																	{
																		code: "GBP",
																	},
																)
																.split(".00")[0]
														}
													</>
												)} */}
												{
													currency
														.format(
															ProductDetail?.ProductDetail?.discountedprice == 0
																? ProductDetail?.ProductDetail?.discountedprice
																: ProductDetail?.ProductDetail?.unit_price,
															{
																code: "GBP",
															},
														)
														.split(".00")[0]
												}
											</strike>
										</h2>
									</div>

									{/* <div className="number">
										<button
											// onClick={() =>
											// 	handleIncrementQuantity(product)
											// }
											className="plus quantity disabled"
											type="button"
											// disabled={
											// 	product.cartQuanity >= product.current_stock
											// }
										>
											+
										</button>
										<input
											type="text"
											// value={product.cartQuanity}
										/>
										<button
											// onClick={() =>
											// 	handleDecreasedQuantity(product)
											// }
											className="minus quantity"
											type="button"
										>
											-
										</button>
									</div> */}
									{ProductDetail?.ProductDetail?.current_stock <= 0 ? (
										<>
											<h5>Out of stock</h5>
										</>
									) : (
										<>
											<div className="number align-item-center">
												<button
													type="button"
													onClick={handleIncrementQuantity}
													className="plus quantity align-item-center"
												>
													+
												</button>
												<input readOnly type="text" value={quantity} />
												<button
													type="button"
													onClick={handleDecreasedQuantity}
													className="minus quantity align-item-center"
												>
													-
												</button>
											</div>
										</>
									)}
								</div>
								<div className="button-wrapper">
									<button
										onClick={() =>
											addToCartHandler(ProductDetail?.ProductDetail?.id)
										}
										disabled={
											ProductDetail?.ProductDetail?.current_stock < 1
												? true
												: false
										}
										className="cart-btn form-control"
									>
										<i className="fa fa-shopping-cart"></i> Add To Cart
									</button>

									<button
										onClick={() => handleWishlist()}
										className="wishlist-wrapper form-control"
									>
										Add To wishlist
									</button>
								</div>
								<div className="other-spec-wrapper">
									<ul>
										<li>
											<span className="property">
												Excluding VAT :{" "}
												<span className="value">
													{ProductDetail?.ProductDetail?.tax}%
												</span>
											</span>
										</li>
										{/* <li>
											<span className="property">
												Recommended retail price :
											</span>
											<span className="value">50,00 $</span>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div>
						<img
							src="img/product-detialline.png"
							className="img-fluid"
							alt=""
						/>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="detail-tabs-wrapper">
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item" role="presentation">
										<a
											className="nav-link active"
											id="home-tab"
											data-toggle="tab"
											href="#home"
											role="tab"
											aria-controls="home"
											aria-selected="true"
										>
											Description
										</a>
									</li>
									<li className="nav-item" role="presentation">
										<a
											className="nav-link"
											id="profile-tab"
											data-toggle="tab"
											href="#profile"
											role="tab"
											aria-controls="profile"
											aria-selected="false"
										>
											Reviews
										</a>
									</li>
								</ul>
								<div className="tab-content" id="myTabContent">
									<div
										className="tab-pane fade show active"
										id="home"
										role="tabpanel"
										aria-labelledby="home-tab"
									>
										<div className="des-content-wrapper">
											<h4>Product Information</h4>
											<div
												className="product-des"
												dangerouslySetInnerHTML={{
													__html: ProductDetail?.ProductDetail?.details,
												}}
											/>
										</div>
										<div className="row pt-5 Related">
											<div className="col-lg-12 pb-3">
												<div className="content-wrapper text-center">
													<h2>Related Products</h2>
												</div>
											</div>
											<RelatedProduct limit={4} />
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="profile"
										role="tabpanel"
										aria-labelledby="profile-tab"
									>
										<div className="blog-replies-wrapper">
											<div className="row">
												<div className="col-sm-12 pb-5">
													<h4>Our Clients Reviews</h4>
												</div>
												<ProductReview Review_id={id} />
												<AddReview product_id={id} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Product Detail Sec Start Here */}

			{/* Footer */}
			<Footer />
			{/* Footer */}
		</>
	);
};

export default ProductDetail;

{
	/* <h2>
											<span className="sales-price mr-2">
												£
												{ProductDetail?.ProductDetail?.discountedprice
													? Math.round(
															ProductDetail?.ProductDetail?.discountedprice,
													  )
													: ProductDetail?.ProductDetail?.unit_price}
											</span>
											<strike className="regular-price">
												{"£" + ProductDetail?.ProductDetail?.discount == "0" ? (
													<>{ProductDetail?.ProductDetail?.discount}</>
												) : (
													<></>
												)}
											</strike>
										</h2> */
}
