import React, { useEffect, useState } from "react";
import { Slider } from "@mui/material";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchProducts,
	SendPriceFilter,
	SendRating,
} from "../../store/productSlice";
import Category from "../Category/Category";

function valuetext(value) {
	return `${value}°C`;
}

const Filter = ({
	newFunc,
	Rvalue,
	setRValue,
	value,
	setValue,
	handleChange,
	clearAllFilters,
	PriceFilter,
}) => {
	const dispatch = useDispatch();
	// const [Rvalue, setRValue] = useState(0);
	// const [value, setValue] = useState([0, 0]);
	// const handleChange = (event, newValue) => {
	// 	setValue(newValue);
	// };

	//Price Filter

	// useEffect(() => {
	// 	let data = {
	// 		from_price: value[0],
	// 		to_price: value[1],
	// 		page: currentPageState,
	// 	};
	// 	dispatch(fetchProducts(data));
	// }, [value, currentPageState]);

	// const HandleFilter = () => {
	// 	let data = {
	// 		from_price: value[0],
	// 		to_price: value[1],
	// 		page: currentPageState,
	// 	};
	// 	dispatch(fetchProducts(data));
	// };

	// const HandleFilter = () => {
	// let data = {
	// 	from_price: value[0],
	// 	to_price: value[1],
	// 	page: currentPageState,
	// };
	// };
	// SendRating,SendPriceFilter

	// const handleRating = () => {
	// 	dispatch(SendRating(Rvalue));
	// 	newFunc();
	// };

	// useEffect(() => {
	// 	dispatch(SendPriceFilter(value));

	// }, [value, Rvalue]);

	// clear All Filters

	// useEffect(() => {

	// }, [Rvalue]);

	// const HandleRating = () => {
	// 	dispatch(SendRating(Rvalue));
	// 	newFunc();
	// };

	// useEffect(() => {
	// 	let data = {
	// 		rating: Rvalue,
	// 		page: currentPageState,
	// 	};
	// 	dispatch(fetchProducts(data));
	// }, [Rvalue]);

	return (
		<>
			<div className="filter">
				<div className="sidebar-card-wrapper cati-sildbar">
					<div className="sidebar-heading-wrapper">
						<h4>Categories</h4>
					</div>
					<div className="sidebar-content-wrapper">
						<div className="categories">
							<ul>
								<Category />
							</ul>
						</div>
					</div>
				</div>
				<div className="sidebar-card-wrapper">
					<div className="sidebar-heading-wrapper">
						<h4>Price Range</h4>
					</div>
					<div className="sidebar-content-wrapper">
						<Slider
							getAriaLabel={() => "Temperature range"}
							value={value}
							onChange={handleChange}
							valueLabelDisplay="auto"
							getAriaValueText={valuetext}
							min={1}
							max={10000}
						/>
						<div>
							<button className="btn btn-sm" onClick={PriceFilter}>
								Price Filter
							</button>
						</div>
					</div>
				</div>
				<div className="sidebar-card-wrapper">
					<div className="sidebar-heading-wrapper">
						<h4>Rated</h4>
					</div>
					<div className="sidebar-content-wrapper rating-wrappers">
						<Stack spacing={1}>
							<Rating
								name="simple-controlled"
								size="large"
								value={Rvalue}
								onChange={(event, newValue) => {
									setRValue(newValue);
								}}
							/>
						</Stack>
					</div>
				</div>
				<div className="sidebar-card-wrapper">
					<div className="sidebar-heading-wrapper">
						<h4>Clear Filter</h4>
					</div>
					<div className="sidebar-content-wrapper rating-wrappers">
						<Stack spacing={1}>
							<div>
								<button className="btn btn-sm" onClick={clearAllFilters}>
									Clear Filter
								</button>
							</div>
						</Stack>
					</div>
				</div>
			</div>
		</>
	);
};

export default Filter;
