import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { SendTokenStrip } from "../../store/productSlice";
import { clearCart } from "../../store/cartSlice";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
	"pk_test_51LGMGbHy66FgldI6FGUpwfMM9JNtpJF5UY5c59PyyA6UwUylL0V1utTfVeX4RopkrIrjDS4FxgZDis42pgSShsL0002KpasdW5",
);

const CheckoutForm = () => {
	const navigate = useNavigate();
	//   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
	const dispatch = useDispatch();
	const [payProcessing, setPayProcessing] = useState(false);
	//   const [error, setError] = useState(false);
	const [done, setDone] = useState(false);
	const { addressId, deliverId, Coupon, message } = useSelector(
		(state) => state.product,
	);
	const product = useSelector((state) => state.cart);
	const mapping = product.map((item) => {
		let data = {
			id: item.id,
			qty: item.cartQuanity,
			variation: item.variation,
			color: item.colors,
		};
		return data;
	});

	useEffect(() => {
		if (message == "Order Placed Successfully") {
			navigate("/thankyou");
			dispatch(clearCart());
		}
	}, [message]);

	const stripe = useStripe();
	const elements = useElements();
	const [paybutton, setPayButton] = useState(true);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (elements == null) {
			return;
		}
		const cardElement = elements.getElement(CardElement);
		const payload = await stripe.createToken(cardElement);
		// setCardToken(payload?.token?.id);
		if (payload?.token?.id) {
			setPayProcessing(true);
			setDone(true);
			setPayButton(false);
			const formData = new FormData();
			formData.set("coupon_code", localStorage.getItem("Coupon"));
			formData.set("address_id", addressId);
			formData.set("shipping_id", deliverId);
			formData.set("stripe_token", payload.token.id);
			formData.set("cart", JSON.stringify(mapping));
			dispatch(SendTokenStrip(formData));
			cardElement.clear();
			// dispatch(clearCart());

			return;
		} else {
			toast.error("Something Went Wrong");
			// setTimeout(() => {
			// 	closeForm();
			// }, 2000);
			cardElement.clear();
			return;
		}
	};

	return (
		<>
			{}
			<form>
				<CardElement
					onChange={(e) => {
						if (e.complete) {
							setPayButton(false);
						} else {
							setPayButton(true);
						}
					}}
				/>
				<br />

				<ul className="list-inline button-group">
					<li className="button-group">
						<button
							type="submit"
							style={{
								width: "100%",
								padding: "12px 80px",
								cursor: "pointer",
								background: "#1aac7a",
								color: "#fff",
								fontSize: "18px",
								textTransform: "uppercase",
								fontWeight: "600",
								border: 0,
								transition: "0.6s",
								border: "2px solid #fff",
							}}
							className="btn form-control"
							disabled={!stripe || !elements || paybutton}
							onClick={handleSubmit}
							// onClick={props.handleClickOpen}
						>
							Pay Now
						</button>
					</li>
				</ul>
			</form>
		</>
	);
};

const StripeForm = () => {
	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm />
		</Elements>
	);
};

export default StripeForm;
