import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Component = ({ children }) => {
	const isLogin = JSON.parse(localStorage.getItem("Token"));
	let location = useLocation();
	if (!isLogin) {
		return <Navigate to="/signin" state={{ from: location }} replace />;
	} else {
		return children;
	}
};

export default Component;
