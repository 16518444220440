// Stylesheet
import "../../assets/css/shop.css";
// Component Import
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Products from "../../components/Products/Products";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Filter/Filter";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
	fetchProducts,
	Search,
	SendCateNameLice,
	SendCatId,
	SendDiscount,
	SendPriceFilter,
	SendSearchVal,
} from "../../store/productSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { add } from "../../store/cartSlice";
import SearchImg from "../../assets/img/search.png";
import { Rating } from "react-simple-star-rating";
import InnerBanner2 from "../../components/InnerBanner/InnerBanner2";

const Shop = () => {
	const { data: products, cartQuanity } = useSelector((state) => state.cart);
	// SubData
	const searching = useSelector((state) => state.product?.SearchData?.products);
	const {
		ProductData,
		message,
		Catid,
		priceFilter,
		rating,
		mainSearvhFilter,
		discountCode,
		ProdesCode,
	} = useSelector((state) => state.product);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [currentPageState, setcurrentPageState] = useState(1);
	const [ShowHighToLow, setShowHighToLow] = useState();

	const [term, setTerm] = useState("");
	const pages = Math.ceil(ProductData?.total_size / ProductData?.limit);
	// const [pages, setpages] = useState(0);
	const handlePageClick = (data) => {
		let currentPage = data.selected + 1;
		setcurrentPageState(currentPage);
	};
	const [Rvalue, setRValue] = useState(0);
	const [value, setValue] = useState([0, 0]);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const newFunc = (e) => {
		if (e) {
			e.preventDefault();
		}
		let CategoId = Catid ? parseInt(Catid) : null;
		// let raTing = rating ? parseInt(rating) : 0;
		let data = {
			category_id: CategoId,
			rating: Rvalue,
			from_price: priceFilter[0],
			to_price: priceFilter[1],
			name: term ? term : mainSearvhFilter,
			price_range: ShowHighToLow,
			page: currentPageState,
			page: currentPageState,
			discount: discountCode,
			cat_name: ProdesCode,
		};
		dispatch(fetchProducts(data));
		// dispatch(Search(currentPageState));
	};

	let clearAllFilters = () => {
		setTerm("");
		setValue([0, 0]);
		setRValue(0);
		setShowHighToLow(null);
		dispatch(SendCatId(0));
		dispatch(SendSearchVal(null));
		dispatch(SendDiscount(0));
		dispatch(SendCateNameLice(""));
	};

	const PriceFilter = () => {
		dispatch(SendPriceFilter(value));
	};

	useEffect(() => {
		newFunc();
		window.scroll(0, 0);
	}, [
		currentPageState,
		Catid,
		mainSearvhFilter,
		Rvalue,
		term,
		ShowHighToLow,
		priceFilter,
		discountCode,
		ProdesCode,
	]);

	const handleAdd = (prodata) => {
		dispatch(add(prodata));
	};

	// const handleSearch = (e) => {
	// 	if (e) {
	// 		e.preventDefault();
	// 	}
	// 	let data = {
	// 		name: term,
	// 		page: currentPageState,
	// 	};
	// 	dispatch(fetchProducts(data));
	// };

	// const handleHight = () => {
	// 	let data = {
	// 		price_range: ShowHighToLow,
	// 	};
	// 	dispatch(fetchProducts(data));
	// };

	return (
		<>
			{/* Header Component */}
			<Header currentPageState={currentPageState} />
			{/* Header Component */}

			{/* Inner Banner */}
			<InnerBanner2 />
			{/* Inner Banner */}

			{/* Shop Sec */}
			<section className="shop">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Filter
								currentPageState={currentPageState}
								term={term}
								setterm={setTerm}
								newFunc={newFunc}
								Rvalue={Rvalue}
								setRValue={setRValue}
								value={value}
								setValue={setValue}
								handleChange={handleChange}
								clearAllFilters={clearAllFilters}
								PriceFilter={PriceFilter}
							/>
						</div>
						<div className="col-lg-9">
							<div className="row search-and-select">
								<div className="col-lg-6">
									<div className="search-wrapper d-flex">
										<input
											type="text"
											placeholder="Search"
											className="form-control"
											value={term}
											onChange={(e) => setTerm(e.target.value)}
										/>
										<button
											className="d-flex align-items-center SearchingBtn"
											onClick={newFunc}
										>
											<span className="align-self-center">|</span> &nbsp; &nbsp;
											&nbsp;
											<img className="SearchIcon" src={SearchImg} alt="" />
										</button>
									</div>
								</div>
								<div className="col-lg-2"></div>
								<div className="col-lg-4">
									<select
										name=""
										defaultValue={"Default Sorting"}
										className="form-control"
										value={ShowHighToLow}
										onChange={(e) => setShowHighToLow(e.target.value)}
										onClick={newFunc}
									>
										<option>Default Sorting</option>
										<option value="desc">High To Low</option>
										<option value="asc">Low To High</option>
									</select>
								</div>
							</div>
							<div className="row single-product-card">
								{searching ? (
									<>
										{searching?.map((prodata, index) => (
											<div key={prodata?.id} className="col-lg-4">
												<div className="single-product-card-wrapper">
													<Link to={`/product-detail/${prodata?.id}`}>
														<div className="single-producrt-img-wrapper">
															<figure>
																<img
																	src={prodata?.thumbnail}
																	className="img-fluid"
																	alt=""
																/>
															</figure>
														</div>
														<div className="single-product-content-wrapper">
															{/* <h6 className="single-product-category">lorem</h6> */}
															<h5 className="single-product-name">
																{prodata?.name}
															</h5>
															<div className="review-wrapper">
																<ul>
																	{prodata?.rating.length > 0 ? (
																		<>
																			{prodata?.rating?.map((items, index) => {
																				return (
																					<Rating
																						key={index}
																						size={18}
																						readonly={true}
																						ratingValue={
																							Math.round(items[0]?.average) ===
																							1
																								? "20"
																								: Math.round(items?.average) ===
																								  2
																								? "40"
																								: Math.round(items?.average) ===
																								  3
																								? "60"
																								: Math.round(items?.average) ==
																								  4
																								? "80"
																								: Math.round(items?.average) ===
																								  5
																								? "100"
																								: null
																						}
																					/>
																				);
																			})}
																			<li className="reviewsSize">
																				({prodata?.reviews_count} reviews)
																			</li>
																		</>
																	) : (
																		<>
																			<Rating size={18} readonly={true} />
																			<li className="reviewsSize">
																				({prodata?.reviews_count} reviews)
																			</li>
																		</>
																	)}
																	<span className="total-reviews"></span>
																</ul>
															</div>
															<div className="price-wrapper">
																<h6 className="price">
																	£{prodata?.unit_price}
																	<span className="regular-price">
																		£{prodata?.purchase_price}
																	</span>
																</h6>
															</div>
															<div className="cart-button-wrapper">
																{prodata?.current_stock == 0 ? (
																	<>
																		<button
																			type="button"
																			className="btn form-control"
																			style={{
																				background: "#1aac7a",
																				color: "white",
																				cursor: "not-allowed",
																			}}
																			disabled
																		>
																			<i className="fa fa-shopping-cart"></i>
																			&nbsp; Add To Cart
																		</button>
																	</>
																) : (
																	<>
																		<button
																			type="button"
																			onClick={() => handleAdd(prodata)}
																			className="btn form-control"
																		>
																			<i className="fa fa-shopping-cart"></i>
																			&nbsp; Add To Cart
																		</button>
																	</>
																)}
															</div>
														</div>
													</Link>
												</div>
											</div>
										))}
										<div className="col-lg-12">
											<p className="total-result mb-0">
												Showing {currentPageState} of 9 results
											</p>
										</div>
										<div className="col-lg-6 mt-4">
											<div className="paginations">
												<ReactPaginate
													breakLabel="..."
													nextLabel=">"
													previousLabel="<"
													onPageChange={handlePageClick}
													marginPagesDisplayed={0}
													pageRangeDisplayed={4}
													pageCount={pages}
												/>
											</div>
										</div>
									</>
								) : (
									<>
										{message == "Product not found!" ? (
											<div className="container">
												<div className="row">
													<div className="col-lg-12 col-md-12">
														<div className="d-flex align-center justify-content-center align-items-center">
															<h1 className="h1Padding">Product not found!</h1>
														</div>
													</div>
												</div>
											</div>
										) : (
											<>
												<Products page={"shop"} />
												<div className="col-lg-12">
													<p className="total-result mb-0">
														Showing {currentPageState} of{" "}
														{parseInt(pages) ? parseInt(pages) : 1}
														&nbsp; results
													</p>
												</div>
												<div className="col-lg-6 mt-4">
													<div className="paginations">
														<ReactPaginate
															breakLabel="..."
															nextLabel=">"
															previousLabel="<"
															onPageChange={handlePageClick}
															marginPagesDisplayed={0}
															pageRangeDisplayed={4}
															pageCount={pages}
														/>
													</div>
												</div>
											</>
										)}
									</>
								)}
								{/* <div className="col-lg-4"> */}
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Shop Sec */}

			{/* Header Component */}
			<Footer />
			{/* Header Component */}
		</>
	);
};

export default Shop;
