import React, { useState, useEffect } from "react";
import "../../assets/css/checkout.css";
import { visaImg } from "../../constant/Index";
import StripeForm from "./StripeForm";
import "../../assets/css/checkout.css";
import { useSelector, useDispatch } from "react-redux";
import {
	AddAddress,
	getAddress,
	getShippingMethod,
	SendTokenStrip,
	setDeliverId,
	setPaymentMethod,
} from "../../store/productSlice";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../store/cartSlice";

const Addetails = () => {
	const { user } = useSelector((state) => state.auth);
	const { GetShippingAddress } = useSelector((state) => state.product);
	const [fName, setFName] = useState(user.f_name);
	const [lName, setLName] = useState(user.l_name);
	const [phone, setPhone] = useState(user.phone);
	const [email, setEmail] = useState(user.email);
	const [method, setMethod] = useState(false);
	const [apartment, setApartment] = useState("");
	const [zip, setZip] = useState(user.zip);
	const [city, setCity] = useState(user.city);
	const [country, setCountry] = useState(user.country);
	const [address, setAddress] = useState(user.street_address);
	const [state, setState] = useState(user.state);
	const [ship_role, setShip_role] = useState("");
	const [addADDress, setAddAddress] = useState(false);
	const dispatch = useDispatch();
	const { addressId, deliverId, Coupon, isPageChange, message } = useSelector(
		(state) => state.product,
	);
	const navigate = useNavigate();
	const product = useSelector((state) => state.cart);
	const mapping = product.map((item) => {
		let data = {
			id: item.id,
			qty: item.cartQuanity,
			variation: item.variation,
			color: item.colors,
		};
		return data;
	});

	useEffect(() => {
		dispatch(getAddress());
	}, [dispatch]);

	const HandleShip = () => {
		// let data = {
		// 	id: ship_role,
		// };
		// dispatch(getShippingMethod(data));
		dispatch(setDeliverId(ship_role));
	};

	const HandlePayment = () => {
		setMethod(false);
	};

	const HandlePayment2 = () => {
		setMethod(true);
	};

	const handleChangeAddress = (e) => {
		e.preventDefault();
		let form = new FormData();
		form.append("contact_person_name", fName + lName);
		form.append("phone", phone);
		form.append("email", email);
		form.append("apartment", apartment);
		form.append("zip", zip);
		form.append("city", city);
		form.append("country", country);
		form.append("address", address);
		form.append("state", state);
		dispatch(AddAddress(form));
		dispatch(getAddress());
	};
	const HandleAddAddress = () => {
		setAddAddress(!addADDress);
	};

	const HandlePurchase = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.set("coupon_code", localStorage.getItem("Coupon"));
		formData.set("address_id", addressId);
		formData.set("shipping_id", deliverId);
		formData.set("cart", JSON.stringify(mapping));
		dispatch(SendTokenStrip(formData));
	};

	useEffect(() => {
		if (message == "Order Placed Successfully") {
			navigate("/thankyou");
			dispatch(clearCart());
		}
	}, [message]);

	return (
		<>
			<div className="add-new-address">
				<div className="form-group mb-4">
					<label htmlFor="method">Shipment Method</label>
					<select
						id="method"
						defaultValue="Select Shipment method"
						className="form-control"
						name="ship_role"
						onChange={(e) => setShip_role(e.target.value)}
						value={ship_role}
						onClick={HandleShip}
					>
						<option>Select Shipment method</option>
						{GetShippingAddress &&
							GetShippingAddress.map((item, index) => {
								return (
									<>
										<option key={index} value={item.id}>
											{item && item.title}
										</option>
										{/* <option value="2">Select Shipment method 2</option> */}
										{/* <option value="3">Select Shipment method 3</option> */}
									</>
								);
							})}
					</select>
				</div>
				<div className="form-check py-3">
					<input
						type="checkbox"
						className="form-check-input ana"
						id="exampleCheckana"
						onClick={HandleAddAddress}
					/>
					<label
						className="form-check-label label_ana"
						htmlFor="exampleCheckana"
					>
						Add New Address
					</label>
				</div>

				{addADDress ? (
					<div>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="fname">First Name</label>
									<input
										type="text"
										id="fname"
										className="form-control"
										value={fName}
										onChange={(e) => setFName(e.target.value)}
										style={{
											backgroundImage:
												'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
											backgroundRepeat: "no-repeat",
											backgroundAttachment: "scroll",
											backgroundSize: "16px 18px",
											backgroundPosition: "98% 50%",
										}}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="lname">Last Name</label>
									<input
										type="text"
										id="lname"
										className="form-control"
										value={lName}
										onChange={(e) => setLName(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="phone">Phone</label>
									<input
										type="text"
										id="phone"
										className="form-control"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										type="text"
										id="email"
										className="form-control"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="address">Address</label>
									<input
										type="text"
										id="address"
										className="form-control"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="apartmnt">Suite, Apartment, etc </label>
									<input
										type="text"
										id="apartmnt"
										className="form-control"
										value={apartment}
										onChange={(e) => setApartment(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="city">City</label>
									<input
										type="text"
										id="city"
										className="form-control"
										value={city}
										onChange={(e) => setCity(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label htmlFor="region">Country/Region</label>
									<input
										type="text"
										id="region"
										className="form-control"
										value={country}
										onChange={(e) => setCountry(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label htmlFor="state">State</label>
									<input
										type="text"
										id="state"
										className="form-control"
										value={state}
										onChange={(e) => setState(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label htmlFor="zip-code">Zip Code</label>
									<input
										type="text"
										id="zip-code"
										className="form-control"
										value={zip}
										onChange={(e) => setZip(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-check">
									<input
										type="checkbox"
										className="form-check-input"
										id="exampleCheckssd"
									/>
									<label
										className="form-check-label label_ssd"
										htmlFor="exampleCheckssd"
									>
										Save Shipping Details
									</label>
								</div>
							</div>
							<div className="col-md-12">
								<button
									type="submit"
									style={{
										marginTop: "20px",
										width: "100%",
										padding: "12px 80px",
										cursor: "pointer",
										background: "#1aac7a",
										color: "#fff",
										fontSize: "18px",
										textTransform: "uppercase",
										fontWeight: "600",
										border: "0",
										transition: "0.6s",
										border: "2px solid #fff",
									}}
									className="btn"
									onClick={handleChangeAddress}
								>
									Save Address
								</button>
							</div>
						</div>
					</div>
				) : null}
			</div>
			<div className="payment-details">
				<div className="heading">
					<h3>Payment Details</h3>
				</div>
				<div className="saved-payment-method">
					<h5>Use Saved Payments</h5>
					<input
						type="radio"
						id="radio1"
						name="Paymentradio"
						onClick={HandlePayment}
						defaultChecked="checked"
					/>
					&nbsp;
					<label htmlFor="radio1" className="mr-4">
						Credit
					</label>
					<input
						type="radio"
						id="radio2"
						name="Paymentradio"
						onClick={HandlePayment2}
					/>
					&nbsp;
					<label htmlFor="radio2">Cash</label>
					<br />
					<br />
					{method == true && (
						<button className="btn" onClick={HandlePurchase}>
							Pay Now
						</button>
					)}
					<br />
					<br />
					<br />
					{method == false && <StripeForm />}
				</div>
			</div>
		</>
	);
};

export default Addetails;
