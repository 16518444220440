import React, { useEffect } from "react";
import { BlogImg1, BlogImg2, BlogImg3 } from "../../constant/Index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLatestBlogs } from "../../store/Blogs/blogSlice";

const Blogs = (props) => {
	const Latestblogs = useSelector((state) => state.blogs);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchLatestBlogs());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	let images = [
		{
			img: BlogImg1,
		},
		{ img: BlogImg2 },
		{ img: BlogImg3 },
	];

	const Blogs = [
		{
			id: 1,
			created_at: "26 Sep 2022",
			img: BlogImg1,
			content:
				"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua consetetur sadipscing.",
		},
		{
			id: 2,
			created_at: "26 Sep 2022",
			img: BlogImg2,
			content:
				"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua consetetur sadipscing.",
		},
		{
			id: 3,
			created_at: "26 Sep 2022",
			img: BlogImg3,
			content:
				"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua consetetur sadipscing.",
		},
	];
	return (
		<>
			{Blogs.map((val, index) => {
				return (
					<div key={index} className="col-lg-4">
						<div className="blog-card-wrapper">
							<Link to={`/blog-detail/${val.id}`}>
								<div className="blog-img-wrapper">
									<figure>
										<img src={val.img} alt="" />
									</figure>
									<div className="hover_deee"></div>
								</div>
							</Link>
							<div className="blog-content-wrapper">
								<div className="blo-date-and-authar-wrapper">
									<ul>
										<li>
											<Link to={`/blog-detail/${val.id}`}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16.331" height="18.146" viewBox="0 0 16.331 18.146">
  <path id="Icon_material-date-range" data-name="Icon material-date-range" d="M9.944,11.165H8.129V12.98H9.944Zm3.629,0H11.758V12.98h1.815Zm3.629,0H15.387V12.98H17.2Zm1.815-6.351h-.907V3H16.295V4.815H9.036V3H7.222V4.815H6.315A1.806,1.806,0,0,0,4.509,6.629L4.5,19.331a1.814,1.814,0,0,0,1.815,1.815h12.7a1.82,1.82,0,0,0,1.815-1.815V6.629A1.82,1.82,0,0,0,19.016,4.815Zm0,14.516H6.315V9.351h12.7Z" transform="translate(-4.5 -3)" fill="#44a548"/>
</svg>

												{val?.created_at}
											</Link>
										</li>

										<li>
											<Link to={`/blog-detail/${val.id}`}>
											<svg xmlns="http://www.w3.org/2000/svg" width="17.145" height="17.146" viewBox="0 0 17.145 17.146">
  <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M8.573,9.644A4.822,4.822,0,1,0,3.751,4.822,4.823,4.823,0,0,0,8.573,9.644Zm4.286,1.072H11.014a5.829,5.829,0,0,1-4.882,0H4.286A4.286,4.286,0,0,0,0,15v.536a1.608,1.608,0,0,0,1.607,1.607H15.538a1.608,1.608,0,0,0,1.607-1.607V15A4.286,4.286,0,0,0,12.859,10.716Z" fill="#44a548"/>
</svg>
 By Admin
											</Link>
										</li>
									</ul>
								</div>
								<p className="blog-des">{val?.content}</p>

								<p className="blog-des"></p>
								<Link to={`/blog-detail/${val.id}`} className="btn btnBlogs">
									Read More
								</Link>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Blogs;
