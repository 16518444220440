import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/signup.css";
import "../../assets/css/signin.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	AuthImg,
	FacebookImg,
	GoogleImg,
	TwitterImg,
} from "../../constant/Index";
import { useSelector, useDispatch } from "react-redux";
import { changePass } from "../../store/auth/authSlice";

const CreatePass = () => {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPass] = useState("");
	const dispatch = useDispatch();
	const { isSuccess, message } = useSelector((state) => state.auth);

	useEffect(() => {
		sessionStorage.removeItem("email");
	}, []);

	const formData = new FormData();
	formData.set("password", password);
	formData.set("confirm_password", confirm_password);
	formData.set("token", localStorage.getItem("Token"));

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(changePass(formData));
	};
	if (message == "Password change successfully!") {
		navigate("/signin");
	}
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Inner Banner Sec */}
			<InnerBanner />
			{/* Inner Banner Sec */}

			<section className="register-user">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="register-img">
								<img src={AuthImg} className="img-fluid" alt="" />
								<div className="text-on-img text-center text-white">
									<h3 className="mb-4 font-weight-normal">
										Welcome to Nutritions24
									</h3>
									<p className="mb-4">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt
									</p>
									<div className="get-connected mb-4">
										<span>Get Connected With</span>
									</div>
									<div className="social-icons mb-5">
										<ul className="row justify-content-center">
											<li>
												<Link to="#">
													<img
														className="img-fluid twitter"
														src={TwitterImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid facebook"
														src={FacebookImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid google"
														src={GoogleImg}
														alt=""
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="auth-btn text-right">
								<Link to="/signin" className="btn light-btn mr-2">
									Sign in
								</Link>
								<Link to="/signup" className="btn">
									Sign up
								</Link>
							</div>
							<div className="signup-form">
								<h2 className="mb-4">Change Password</h2>
								<p className="mb-4">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore.
								</p>
								<hr />
								<form method="POST" onSubmit={handleSubmit}>
									<div className="row mt-4">
										<div className="col-md-12 mt-4">
											<label htmlFor="">Password</label>
											<input
												type="password"
												name="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												className="form-control"
											/>
										</div>
										<div className="col-md-12 mt-4">
											<label htmlFor="">Confirm Password</label>
											<input
												type="password"
												name="confirm_password"
												value={confirm_password}
												onChange={(e) => setConfirmPass(e.target.value)}
												className="form-control"
											/>
										</div>
										<div className="col-md-12 mt-5">
											<button className="btn w-100" type="submit">
												Send
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Header Sec */}
			<Footer />
			{/* Header Sec */}
		</>
	);
};

export default CreatePass;
