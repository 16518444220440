import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Avatar, headlogo } from "../../constant/Index";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchProducts,
	HomeCategories,
	SendCatId,
	SubCategories,
	SendSearchVal,
	AddCartGetAll,
	SendCateNameLice,
	SendCateNamess,
} from "../../store/productSlice";
import menu3 from "../../assets/img/menu3.jpg";
import menu4 from "../../assets/img/menu4.jpg";

import { SearchIcon } from "../../constant/Index";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Header = ({ currentPageState }) => {
	const [loader, setLoader] = useState(false);
	const [run, setRun] = useState(0);
	const [mainSearch, setMainSearch] = useState("");
	const [dropdown, setDropdown] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let location = useLocation();
	const searching = useSelector((state) => state.product?.SearchData);
	const homeCategory = useSelector((state) => state.product?.HomeCatData);
	const subProduct = useSelector((state) => state.product?.SubData);
	const subcat = useSelector((state) => state.product?.SubCategory);
	const { user } = useSelector((state) => state.auth);
	useEffect(() => {
		dispatch(HomeCategories());
	}, []);
	const isLogin = JSON.parse(localStorage.getItem("Token"));
	const { Cart } = useSelector((state) => state.product);
	const [Show, setShow] = useState(false);
	const [filter, setFilter] = useState("");
	const subCategoryChoose = async (e) => {
		setLoader(true);
		await dispatch(SubCategories(`${e}`));
		setLoader(false);
	};

	useEffect(() => {
		subCategoryChoose();
	}, []);

	const handleSub = (id, e) => {
		e.preventDefault();
		setDropdown(false);
		dispatch(SendCatId(id));
		setTimeout(() => {
			navigate(`/shop`);
		}, 1000);
	};

	const HandleSeArch = () => {
		dispatch(SendSearchVal(mainSearch));
		navigate(`/shop`);
	};

	const [view, setView] = useState(false);

	const [newStateCheck, setNewStateCheck] = useState(null);
	const showAndHideFunc2 = (e, f) => {
		setView(e);
		setNewStateCheck(f);
	};

	const HandleClickss = (names) => {
		showAndHideFunc2(false);
		dispatch(SendCateNameLice(names));
		dispatch(SendCateNamess(names));
		sessionStorage.setItem("innerName", names);
		navigate("/shop");
	};
	const links = ["link1", "link2", "link3"];
	const [active, setActive] = useState(null);

	const length = 1;

	// const googleTranslateElementInit = () => {
	// 	new window.google.translate.TranslateElement(
	// 		{
	// 			pageLanguage: "en",
	// 			layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
	// 		},
	// 		"trans",
	// 	);
	// };
	// useEffect(() => {
	// 	const unsub = () => {
	// 		setTimeout(() => {
	// 			var addScript = document.createElement("script");
	// 			addScript.setAttribute(
	// 				"src",
	// 				"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
	// 			);
	// 			document.body.appendChild(addScript);
	// 			window.googleTranslateElementInit = googleTranslateElementInit;
	// 		}, 1000);
	// 	};
	// 	return clearTimeout(unsub);
	// }, []);

	// const googleTranslateElementInit = () => {
	// 	new window.google.translate.TranslateElement(
	// 		{
	// 			pageLanguage: "en",
	// 			layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
	// 		},
	// 		"trans",
	// 	);
	// };

	// useEffect(() => {
	// 	var addScript = document.createElement("script");
	// 	addScript.setAttribute(
	// 		"src",
	// 		"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
	// 	);
	// 	document.body.appendChild(addScript);
	// 	window.googleTranslateElementInit = googleTranslateElementInit;
	// }, [googleTranslateElementInit]);

	// const onSearchSubmitHandler = (e) => {
	// 	e.preventDefault();
	// 	dispatch(
	// 		fetchProducts({
	// 			name: searchSlug,
	// 			page: 1,
	// 		}),
	// 	);
	// 	navigate(`/shop`, {
	// 		state: {
	// 			slug: searchSlug,
	// 		},
	// 	});
	// };

	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: "en",
				layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
			},
			"trans",
		);
	};

	useEffect(() => {
		var addScript = document.createElement("script");
		addScript.setAttribute(
			"src",
			"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
		);
		document.body.appendChild(addScript);
		window.googleTranslateElementInit = googleTranslateElementInit;
	}, [googleTranslateElementInit]);

	return (
		<>
			<header id="header">
				<div className="secondary-header-wrapper">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-lg-3">
								<div className="header-cta-wrapper">
									<ul>
										<li className="logo-wrapper">
											<Link to="/">
												<img
													src={headlogo}
													className="img-fluid logo_image"
													alt=""
												/>
											</Link>
										</li>
									</ul>
								</div>
							</div>

							<div className="col-lg-5">
								<ul>
									<li className="input-wrapper search mx-auto">
										<input type="text" name="" id="" placeholder="Search" />
										<button>
											<img src={SearchIcon} alt="SearchBar" />
										</button>
									</li>
								</ul>
							</div>

							<div className="col-lg-4">
								<div className="social-icons-wrapper">
									<ul>
										<li>
											{/* <select defaultValue={"Language"} name="" id="">
												<option value="Language">Language</option>
											</select> */}
											<>
												<div className="translate" id="trans"></div>
											</>
										</li>
										{isLogin ? (
											<Link to="/profile">
												<img
													className="img-fluid"
													width={30}
													src={user?.image}
													alt=""
													style={{
														borderRadius: "50%",
														width: "30px",
														height: "30px",
														// border: "1px solid #434343",
													}}
												/>
											</Link>
										) : (
											<li>
												<Link to="/signin">
													{/* <i
														className="fa fa-user"
														style={{ color: "#434343" }}
													></i> */}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="22.225"
														height="22.225"
														viewBox="0 0 22.225 22.225"
													>
														<path
															id="Icon_awesome-user-alt"
															data-name="Icon awesome-user-alt"
															d="M11.113,12.5A6.251,6.251,0,1,0,4.862,6.251,6.253,6.253,0,0,0,11.113,12.5Zm5.556,1.389H14.277a7.556,7.556,0,0,1-6.329,0H5.556A5.556,5.556,0,0,0,0,19.447v.695a2.084,2.084,0,0,0,2.084,2.084H20.142a2.084,2.084,0,0,0,2.084-2.084v-.695A5.556,5.556,0,0,0,16.669,13.891Z"
															fill="#434343"
														/>
													</svg>
												</Link>
											</li>
										)}
										<li>
											<Link to="/cart">
												{/* <i
													className="fa fa-shopping-cart"
													style={{ color: "#434343" }}
												></i> */}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="22.832"
													height="20.295"
													viewBox="0 0 22.832 20.295"
												>
													<path
														id="Icon_awesome-shopping-cart"
														data-name="Icon awesome-shopping-cart"
														d="M20.934,11.944,22.808,3.7a.951.951,0,0,0-.928-1.162H6.311L5.947.761A.951.951,0,0,0,5.015,0H.951A.951.951,0,0,0,0,.951v.634a.951.951,0,0,0,.951.951h2.77L6.506,16.15a2.22,2.22,0,1,0,2.658.34h8.31a2.219,2.219,0,1,0,2.521-.412l.219-.962a.951.951,0,0,0-.928-1.162H8.646l-.259-1.268h11.62A.951.951,0,0,0,20.934,11.944Z"
														fill="#434343"
													/>
												</svg>

												<span className="total-cart-no">
													{Cart?.length ? Cart?.length : 0}
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="main-header-wrapper">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-2">
								{/* <div className="logo-wrapper">
									<Link to="/">
										<img src={headlogo} className="img-fluid" alt="" />
									</Link>
								</div> */}
							</div>
							<div className="col-8">
								<div className="navbar-items-wrapper">
									<ul className="navbar-nav">
										{homeCategory?.map((item, index) => {
											return (
												<li
													key={index}
													className="nav-item "
													onMouseEnter={() => {
														setDropdown(true);
													}}
												>
													<div class="dropdown health-drop ">
														<button
															className={
																view == true && newStateCheck == index + 1
																	? "button_shashke"
																	: null
															}
															onClick={() => {
																showAndHideFunc2(!view, index + 1);
															}}
														>
															{item?.name}
															<i
																class="fa fa-caret-down"
																aria-hidden="true"
															></i>
														</button>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
							<div className="col-2"></div>
						</div>
					</div>
				</div>
			</header>

			<div className={Show ? "search_box active" : "search_box"}>
				<div className="container">
					<div className="close_btn">
						<button className="close" onClick={() => setShow(false)}>
							x
						</button>
					</div>

					<div className="form-group">
						<input
							className="form-control"
							value={mainSearch}
							onChange={(e) => setMainSearch(e.target.value)}
							placeholder="Search here..."
						/>

						<>
							<button
								className="btn"
								type="submit"
								onClick={() => HandleSeArch()}
							>
								<i className="fa fa-search" aria-hidden="true"></i>
							</button>
						</>
					</div>
				</div>
			</div>
			{(view == true) & (newStateCheck > 0) ? (
				<div className="sec-drop">
					<div className="container-fluid">
						<div className="row">
							{homeCategory?.map((item, index) => {
								return (
									<div className="col-md-2">
										<div className="drop-down-head">
											<div className="div-heding" style={{ cursor: "pointer" }}>
												<h3>{item?.name}</h3>
											</div>
											<div className="div-ul">
												<ul>
													{item.childes.map((itemee) => {
														return (
															<>
																<li
																	className={`${
																		itemee.childes.length > 0
																			? "for-bold-li"
																			: ""
																	}`}
																>
																	<button
																		onClick={() => HandleClickss(item?.name)}
																		style={{ cursor: "pointer" }}
																	>
																		{itemee?.name}
																	</button>
																</li>
																{itemee.childes.length > 0 &&
																	itemee.childes.map((itemas) => (
																		<li>
																			<button
																				onClick={() =>
																					HandleClickss(item?.name)
																				}
																				style={{ cursor: "pointer" }}
																			>
																				{itemas?.name}
																			</button>
																		</li>
																	))}
															</>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Header;
