import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// const initialState = {
//   cartItems: [],
//   cartTotalQuanity: 0,
// };

const cartSlice = createSlice({
	name: "cart",
	initialState: sessionStorage.getItem("cartItem")
		? JSON.parse(sessionStorage.getItem("cartItem"))
		: [],
	reducers: {
		add(state, action) {
			// state.push(action.payload);
			const itemIndex = state.findIndex(
				(item) => item.id === action.payload.id,
			);
			if (itemIndex >= 0) {
				state[itemIndex].cartQuanity += 1;
				toast.info("increased product quantity", {
					position: "top-right",
				});
			} else {
				const tempProduct = { ...action.payload, cartQuanity: 1 };
				state.push(tempProduct);
				toast.success("Added product into cart", {
					position: "top-right",
				});
			}
			sessionStorage.setItem("cartItem", JSON.stringify(state));
		},
		remove(state, action) {
			localStorage.setItem(
				"cartItem",
				JSON.stringify(state.filter((items) => items.id !== action.payload)),
			);
			toast.success("Product removed from cart", {
				position: "top-right",
			});
			return state.filter((items) => items.id !== action.payload);
		},
		decreasedCart(state, action) {
			const itemIndex = state.findIndex(
				(item) => item.id === action.payload.id,
			);
			if (state[itemIndex].cartQuanity > 1) {
				state[itemIndex].cartQuanity -= 1;
				toast.info("Decreased cart quantity", {
					position: "top-right",
				});
			} else if (state[itemIndex].cartQuanity === 1) {
				localStorage.setItem(
					"cartItem",
					JSON.stringify(
						state.filter((items) => items.id !== action.payload.id),
					),
				);
				toast.success("Product removed from cart", {
					position: "top-right",
				});
				return state.filter((items) => items.id !== action.payload.id);
			}
		},
		// ClearAllCart
		clearCart(state) {
			// sessionStorage.setItem("cartItem", JSON.stringify([]));
			localStorage.setItem("cartItems", JSON.stringify([]));
			return [];
		},
	},
});

export const { add, remove, decreasedCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
