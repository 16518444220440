import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Footer from "../../components/Footer/Footer";
import SaveAddress from "./SaveAddress";
import Addetails from "./Addetails";
import OrderSummary from "./OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import { getShippingMethod } from "../../store/productSlice";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const product = useSelector((state) => state.cart);

	useEffect(() => {
		dispatch(getShippingMethod());
	}, []);

	// useEffect(() => {
	// 	if (product.length === 0) {
	// 		navigate("/");
	// 	}
	// }, [product]);
	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			{/* InnerBanner */}
			<InnerBanner />
			{/* InnerBanner */}

			{/* Checkout Page */}
			<section className="checkout">
				<div className="container">
					<form action="thank-you.php">
						<div className="row">
							<div className="col-lg-6">
								<div className="first-top-bar">
									<h3>Shipment Details</h3>
									{/* <!-- <p>Already Have an account? <a href="login.php">Log in</a> </p> --> */}
								</div>

								<div className="saved-address">
									<div className="title">
										<h5>Use Saved Addresses</h5>
									</div>

									<SaveAddress />
								</div>
								<Addetails />
							</div>
							<div className="col-lg-1"></div>
							<div className="col-lg-5">
								<OrderSummary />
							</div>
						</div>
						{/* <div className="border-last"></div> */}
						{/* <div className="confirm-orderDv">
							<div className="checkbox">
								<input
									type="checkbox"
									className="form-control"
									id="for_checkbox"
								/>
								<label htmlFor="for_checkbox" className="for_checkbox_label">
									Proident, sunt in culpa qui officia deserunt mollit anim id
									est laborum.
								</label>
							</div>
							<div className="button-group">
								<button type="submit">
									Confirm Order <img src="img/right-arrow.svg" alt="" />
								</button>
							</div>
						</div> */}
					</form>
				</div>
			</section>
			{/* Checkout Page */}

			{/* Header */}
			<Footer />
			{/* Header */}
		</>
	);
};

export default Checkout;
