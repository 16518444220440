import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import InnerBanner from "../InnerBanner/InnerBanner";

const NotFound = () => {
  return (
    <>
      <div className="page_not_found">
        <Header />
      </div>
      <section className="notFound">
        <div className="pageHeading">
          <h2>404 Not Found</h2>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NotFound;
