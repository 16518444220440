import React from "react";
import { catImage1, cryptoplan, CateImg3, Naplam } from "../../constant/Index";
function HealthWellness() {
	let CardMap = [
		{
			id: 1,
			thumbnail: catImage1,
			title: "All Natural",
		},
		{
			id: 2,
			thumbnail: cryptoplan,
			title: "Cytoplan",
		},
		{
			id: 3,
			thumbnail: CateImg3,
			title: "MB Whey Gold",
		},
		{
			id: 4,
			thumbnail: Naplam,
			title: "Naplam",
		},
	];
	return (
		<>
			<section>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="heath_wellness_main">
								<div className="health_first_heading">
									<p className="Heading_pro">Health & Wellness</p>
									<div className="health_div_flex">
										{CardMap.map((item, index) => (
											<>
												<div className="Main_card_div_health" key={index}>
													<div className="health_card_img2">
														<div className="health_card_img">
															<img src={item.thumbnail} alt="" />
														</div>
														<div className="back_card_title">
															<p>{item.title}</p>
														</div>
													</div>
												</div>
											</>
										))}
									</div>
									<button
										type="button"
										className="btn form-control form-control2 healthBtn"
									>
										See All
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HealthWellness;
