import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/signup.css";
import "../../assets/css/signin.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
	AuthImg,
	FacebookImg,
	GoogleImg,
	TwitterImg,
} from "../../constant/Index";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../../store/auth/authSlice";
import Spinner from "../../components/Spinner/Spinner";

const Signup = () => {
	const [formData, setformData] = useState({
		f_name: "",
		l_name: "",
		email: "",
		phone: "",
		password: "",
		confirm_password: "",
		street_address: "",
		country: "",
		state: "",
		city: "",
		zip: "",
	});

	const {
		f_name,
		l_name,
		email,
		phone,
		password,
		confirm_password,
		street_address,
		country,
		state,
		city,
		zip,
	} = formData;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user, isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth,
	);

	useEffect(() => {
		dispatch(reset());
	}, [user, isError, isSuccess, message, navigate, dispatch]);

	useEffect(() => {
		if (message == "Success!") {
			return navigate("/signin");
		}
	}, [message]);

	const onChange = (e) => {
		setformData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (password !== confirm_password) {
			toast.error("Password do not match");
		} else {
			const userData = {
				f_name,
				l_name,
				email,
				phone,
				password,
				confirm_password,
				street_address,
				country,
				state,
				city,
				zip,
			};
			dispatch(register(userData));
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Signup Sec */}
			<section className="register-user">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="register-img">
								<img src={AuthImg} className="img-fluid" alt="" />
								<div className="text-on-img text-center text-white">
									<h3 className="mb-4 font-weight-normal">
										Welcome to Nutritions24
									</h3>
									<p className="mb-4">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore.
									</p>
									<div className="get-connected mb-4">
										<span>Get Connected With</span>
									</div>
									<div className="social-icons mb-5">
										<ul className="row justify-content-center">
											<li>
												<Link to="#">
													<img
														className="img-fluid twitter"
														src={TwitterImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid facebook"
														src={FacebookImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid google"
														src={GoogleImg}
														alt=""
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="auth-btn text-right">
								<Link to="/signin" className="btn light-btn mr-2">
									Sign in
								</Link>
								<Link to="/signup" className="btn">
									Sign up
								</Link>
							</div>
							<div className="signup-form">
								<h2 className="mb-4">Sign Up</h2>
								<p className="mb-4">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore.
								</p>
								<hr />

								<form onSubmit={onSubmit}>
									<div className="row mt-4">
										<div className="col-md-6">
											<label htmlFor="f_name">First Name</label>
											<input
												type="text"
												id="f_name"
												name="f_name"
												required
												value={f_name}
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-6">
											<label htmlFor="l_name">Last Name</label>
											<input
												type="text"
												id="l_name"
												name="l_name"
												value={l_name}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-6 mt-4">
											<label htmlFor="email">Email Address</label>
											<input
												type="email"
												id="email"
												name="email"
												value={email}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-6 mt-4">
											<label htmlFor="phone">Phone Number</label>
											<input
												type="tel"
												id="phone"
												name="phone"
												value={phone}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-4 mt-4">
											<label htmlFor="phone">Street Address</label>
											<input
												type="text"
												id="street_address"
												name="street_address"
												required
												value={street_address}
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-4 mt-4">
											<label htmlFor="phone">Country</label>
											<input
												type="text"
												id="country"
												name="country"
												value={country}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-4 mt-4">
											<label htmlFor="phone">State</label>
											<input
												type="text"
												id="state"
												name="state"
												required
												value={state}
												onChange={onChange}
												className="form-control"
											/>
										</div>
										{/* <div className="col-md-4 mt-4">
											<label htmlFor="phone">province</label>
											<input
												type="text"
												id="province"
												name="province"
												required
												value={province}
												onChange={onChange}
												className="form-control"
											/>
										</div> */}
										<div className="col-md-4 mt-4">
											<label htmlFor="phone">City</label>
											<input
												type="text"
												id="city"
												name="city"
												required
												value={city}
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-4 mt-4">
											<label htmlFor="phone">Zip</label>
											<input
												type="text"
												id="zip"
												name="zip"
												required
												value={zip}
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col mt-4">
											<label htmlFor="password">Password</label>
											<input
												type="password"
												id="password"
												name="password"
												value={password}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col mt-4">
											<label htmlFor="confirm_password">Confirm Password</label>
											<input
												type="password"
												id="confirm_password"
												name="confirm_password"
												value={confirm_password}
												required
												onChange={onChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-12 mt-5">
											<button type="submit" className="btn w-100">
												Sign up
											</button>
											<ToastContainer />
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Signup Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Signup;
