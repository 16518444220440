import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "./authService";

const Token = JSON.parse(localStorage.getItem("Token"));
const user = JSON.parse(localStorage.getItem("UserData"));

const initialState = {
	user: user ? user : [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	statusCode: "",
	message: "",
	token: Token ? Token : "",
};

// Signup User
export const register = createAsyncThunk(
	"auth/register",
	async (user, thunkAPI) => {
		try {
			return await authService.register(user);
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Signin User
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
	try {
		return await authService.login(user);
	} catch (error) {
		const message =
			(error.response &&
				error.response.errors &&
				error.response.errors.message) ||
			error.message ||
			error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

// forgot User
export const forgot = createAsyncThunk(
	"auth/forgot-password",
	async (identity, thunkAPI) => {
		try {
			return await authService.forgot(identity);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Otp Fill
export const SendOtp = createAsyncThunk(
	"auth/verify-otp",
	async (data, thunkAPI) => {
		try {
			return await authService.otp(data);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Change Pass
export const changePass = createAsyncThunk(
	"auth/reset-password",
	async (data, thunkAPI) => {
		try {
			return await authService.changePass(data);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Change Profile Pass
export const changeProfilePass = createAsyncThunk(
	"profile/change-password",
	async (updatePass, thunkAPI) => {
		try {
			return await authService.profilePass(updatePass);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Change Profile Address
export const changeProfileAddress = createAsyncThunk(
	"profile/change-Address",
	async (updateAddress, thunkAPI) => {
		try {
			return await authService.profileAddress(updateAddress);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Newsletter
export const Newsletter = createAsyncThunk(
	"newsletter",
	async (newsletter, thunkAPI) => {
		try {
			return await authService.subscribeNewsletter(newsletter);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Contact
// export const Contact = createAsyncThunk(
//   "Contact",
//   async (Contactdata, thunkAPI) => {
//     try {
//       return await authService.subscribeNewsletter(Contactdata);
//     } catch (error) {
//       const message = error.response && error.message;
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
export const Contact = createAsyncThunk(
	"contact",
	async (Contactdata, thunkAPI) => {
		try {
			return await authService.Contact(Contactdata);
		} catch (error) {
			const message = error.response && error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// Logout User
export const logout = createAsyncThunk("auth/logout", async () => {
	await authService.logout();
});

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = false;
			state.message = "";
		},
		resetProfile: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(register.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = false;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.meta.arg;
				state.message = action.payload.message;
			})
			.addCase(register.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
				state.isSuccess = false;
			})
			.addCase(login.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.payload?.response?.data?.user;
			})
			.addCase(login.rejected, (state, action) => {
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(logout.fulfilled, (state) => {
				state.user = null;
			})
			.addCase(forgot.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(forgot.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.meta.arg;
				state.message = action.payload.data.message;
			})
			.addCase(forgot.rejected, (state, action) => {
				state.isSuccess = false;
				state.isLoading = false;
				state.isError = true;
				state.message = action.error.message;
			})
			.addCase(SendOtp.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(SendOtp.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload.data.message;
				state.token = action.payload?.data?.response?.data[0];
			})
			.addCase(SendOtp.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.error.message;
			})
			.addCase(changePass.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(changePass.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload?.data?.message;
			});
		// .addCase(Contact.pending, (state) => {
		//   state.isLoading = true;
		// })
		// .addCase(Contact.fulfilled, (state, action) => {
		//   state.isLoading = false;
		//   state.isSuccess = true;
		//   state.user = action.meta.arg;
		//   state.message = action.payload.data.message;
		//   state.statusCode = action.payload.statusCode
		// })
		// .addCase(Contact.rejected, (state, action) => {
		//   state.isSuccess = false;
		//   state.isLoading = false;
		//   state.isError = true;
		//   state.message = action.error.message;
		// })
	},
});

export const { reset, resetProfile } = authSlice.actions;
export default authSlice.reducer;
