import { configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import productReducer from "./productSlice";
import authReducer from "./auth/authSlice";
import siteSettingSlice from "./SiteSetting/siteSettingSlice";
import faqsSlice from "./Faqs/faqsSlice";
import Faqs from "../components/Faqs/Faqs";
import bannerSlice from "./Banner/bannerSlice";
import testimonialSlice from "./Testimonial/testimonialSlice";
import blogSlice from "./Blogs/blogSlice";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    product: productReducer,
    auth: authReducer,
    data: siteSettingSlice.reducer,
    faqs: faqsSlice,
    banner: bannerSlice,
    testimonial: testimonialSlice,
    blogs: blogSlice,
  },
});

export default store;
