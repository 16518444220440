import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	fetchProducts,
	SendCatId,
	SubCategories,
} from "../../store/productSlice";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .03)",
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Category = () => {
	const dispatch = useDispatch();
	const subcat = useSelector((state) => state.product?.SubCategory);
	const categories = useSelector((state) => state.product?.ProductCategories);
	const [loader, setLoader] = useState(false);
	const [expanded, setExpanded] = React.useState("panel1");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const subCategoryChoose = async (e) => {
		setLoader(true);
		await dispatch(SubCategories(`${e}`));
		setLoader(false);
	};

	useEffect(() => {
		subCategoryChoose();
	}, []);

	// const handleSub = (id, e) => {
	// 	e.preventDefault();
	// 	let data = {
	// 		category_id: id,
	// 	};
	// 	dispatch(fetchProducts(data));
	// };
	const handleSub = (id, e) => {
		e.preventDefault();
		dispatch(SendCatId(id));
	};
	// .slice(0, 5)
	return (
		<>
			<div id="accordion">
				{categories &&
					categories.map((category, index) => (
						<div
							onClick={() => {
								subCategoryChoose(category?.slug);
							}}
							key={index}
							className="card"
						>
							<div className="card-header" id={category?.id}>
								<h5 className="mb-0">
									<button
										className="btn btn-link"
										data-toggle="collapse"
										data-target={"#collapse" + category?.id}
										aria-expanded="true"
										aria-controls={"collapse" + category?.id}
									>
										<span onClick={(e) => handleSub(category?.id, e)}>
											{category?.name}
										</span>
										<i class="fa fa-angle-down"></i>
									</button>
								</h5>
							</div>

							<div
								id={"collapse" + category?.id}
								// className={category.id != 1 ? "collapse" : "collapse show"}
								className="collapse"
								aria-labelledby={category?.id}
								data-parent="#accordion"
							>
								<div className="card-body">
									{loader ? (
										<>
											<p>Loading...</p>
										</>
									) : (
										<>
											{subcat == 0 ? (
												<>No category found</>
											) : (
												<>
													{subcat?.map((sub, index) => {
														return (
															<>
																<Accordion
																	className="innerAccordion"
																	expanded={expanded === `panel1 ${index}`}
																	onChange={handleChange(`panel1 ${index}`)}
																>
																	<AccordionSummary
																		aria-controls="panel1d-content"
																		id="panel1d-header"
																	>
																		<p onClick={(e) => handleSub(sub?.id, e)}>
																			{sub?.name?.charAt(0)?.toUpperCase() +
																				sub?.name?.slice(1)}
																		</p>
																	</AccordionSummary>
																	<AccordionDetails>
																		{sub?.childes.map((item, i) => {
																			return (
																				<div key={i + 100}>
																					<Typography
																						style={{
																							cursor: "pointer",
																						}}
																						onClick={(e) =>
																							handleSub(item?.id, e)
																						}
																					>
																						{item.name
																							?.charAt(0)
																							?.toUpperCase() +
																							item?.name?.slice(1)}
																					</Typography>
																				</div>
																			);
																		})}
																	</AccordionDetails>
																</Accordion>
															</>
														);
													})}
												</>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default Category;
