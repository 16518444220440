import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import service from "../../store/auth/authService";

const ChangeAddress = (props) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [state2, setState2] = useState({
		street_address: "",
		country: "",
		state: "",
		province: "",
		city: "",
		zip: "",
	});

	let { street_address, country, state, province, city, zip } = state2;

	useEffect(() => {
		setState2(user);
	}, [user]);

	const HandleChange = (e) => {
		setState2({ ...state2, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let form_Data = new FormData();
		form_Data.set("street_address", street_address);
		form_Data.set("country", country);
		form_Data.set("state", state);
		form_Data.set("province", province);
		form_Data.set("city", city);
		form_Data.set("zip", zip);
		dispatch(service.profileAddress(form_Data));
	};
	return (
		<>
			<div className="pro-edit-address-wrapper">
				<div className="profile-info-box-wrapper">
					<div className="profile-content-heading">
						<h4>Change Address </h4>
					</div>
					<div className="box-fields-wrapper edit-fields">
						<form onSubmit={handleSubmit}>
							<div className="form-group row">
								<div className="col-lg-6">
									<input
										type="text"
										readOnly
										defaultValue={user?.f_name}
										className="form-control"
										placeholder="First Name"
									/>
								</div>
								<div className="col-lg-6">
									<input
										type="text"
										readOnly
										defaultValue={user?.l_name}
										placeholder="Last Name"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-12">
									<input
										value={province}
										name="province"
										onChange={HandleChange}
										type="text"
										placeholder="Province"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-4">
									<input
										value={street_address}
										onChange={HandleChange}
										type="text"
										name="street_address"
										placeholder="Street"
										className="form-control"
									/>
								</div>
								<div className="col-lg-4">
									<input
										value={city}
										onChange={HandleChange}
										type="text"
										name="city"
										placeholder="City"
										className="form-control"
									/>
								</div>
								<div className="col-lg-4">
									<input
										value={state}
										onChange={HandleChange}
										type="text"
										name="state"
										placeholder="State"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-6">
									<input
										value={zip}
										onChange={HandleChange}
										type="text"
										name="zip"
										placeholder="Zip Code"
										className="form-control"
									/>
								</div>
								<div className="col-lg-6">
									<input
										value={country}
										onChange={HandleChange}
										type="text"
										name="country"
										placeholder="Country"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-12 text-right">
									<button
										onClick={() => {
											props.changePortion(1);
										}}
										className="btn mr-3"
									>
										Back
									</button>
									<button type="submit" className="btn">
										Save
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChangeAddress;
