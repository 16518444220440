import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	Alldata: [],
	isSuccess: false,
	isLoading: false,
	message: "",
};

export const fetchPolicies = createAsyncThunk(
	"policies/data",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/sitesetting",
			);

			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

const siteSettingSlice = createSlice({
	name: "data",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPolicies.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchPolicies.fulfilled, (state, action) => {
				state.isLoading = false;
				state.Alldata = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchPolicies.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			});
	},
});

export default siteSettingSlice;
