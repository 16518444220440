import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rev1 } from "../../constant/Index";
import { fetchProductReview } from "../../store/productSlice";

const ProductReview = (props) => {
	const reviews = useSelector((state) => state.product?.ProductReviews);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchProductReview(props.Review_id));
	}, []);
	return (
		<>
			{reviews?.length == 0 ? (
				<>
					<h4 className="pl-3">No Reviews for this product</h4>
				</>
			) : (
				<>
					{reviews?.map((review, index) => {
						console.log(review);
						return (
							<div key={review?.id} className="col-lg-12 mb-4">
								<div className="user-content-wrapper">
									<h5 className="replied-user-name">{review?.name}</h5>
									<p className="replied-user-name">{review?.email}</p>
									<div className="date-and-starts-wrapper d-flex">
										<h6 className="comment-date">
											{review?.created_at.slice(0, 10)}
										</h6>
										<div className="rating-wrapper">
											<ul>
												{[...Array(review?.rating)].map(
													(elementInArray, index) => (
														<li key={index}>
															<i className="fa fa-star"></i>
														</li>
													),
												)}
												{/* {review?.customer?.rating.map((star) => {
                        return (
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        );
                      })} */}
											</ul>
										</div>
									</div>
									<p className="comment-content">{review?.comment}</p>
								</div>
							</div>
						);
					})}
				</>
			)}
		</>
	);
};

export default ProductReview;
