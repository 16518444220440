import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/signup.css";
import "../../assets/css/signin.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AuthImg,
  FacebookImg,
  GoogleImg,
  TwitterImg,
} from "../../constant/Index";

import { useSelector, useDispatch } from "react-redux";
import { forgot } from "../../store/auth/authSlice";

const ForgotPass = () => {
  let navigate = useNavigate();
  const [identity, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isSuccess, message } = useSelector((state) => state.auth);

  const formData = new FormData();
  formData.set("identity", identity);

  useEffect(() => {
    if (message == "Email sent successfully.") {
      return (
        sessionStorage.setItem("email",identity),
        navigate("/otp")
      )
    }
  }, [message]);


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgot(formData));
  };
  return (
    <>
      {/* Header Sec */}
      <Header />
      {/* Header Sec */}

      {/* Inner Banner Sec */}
      <InnerBanner />
      {/* Inner Banner Sec */}

      <section className="register-user">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="register-img">
                <img src={AuthImg} className="img-fluid" alt="" />
                <div className="text-on-img text-center text-white">
                  <h3 className="mb-4 font-weight-normal">
                    Welcome to Nutritions24
                  </h3>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt
                  </p>
                  <div className="get-connected mb-4">
                    <span>Get Connected With</span>
                  </div>
                  <div className="social-icons mb-5">
                    <ul className="row justify-content-center">
                      <li>
                        <Link to="#">
                          <img
                            className="img-fluid twitter"
                            src={TwitterImg}
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            className="img-fluid facebook"
                            src={FacebookImg}
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            className="img-fluid google"
                            src={GoogleImg}
                            alt=""
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="auth-btn text-right">
                <Link to="/signin" className="btn light-btn mr-2">
                  Sign in
                </Link>
                <Link to="/signup" className="btn">
                  Sign up
                </Link>
              </div>
              <div className="signup-form">
                <h2 className="mb-4">Forgot Password</h2>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
                <hr />
                <form method="POST" onSubmit={handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-12 mt-4">
                      <label htmlFor="">Enter Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={identity}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="john***@gmail.com"
                      />
                      <i
                        className="fa fa-envelope-o forgot-icon"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="col-md-12 mt-5">
                      <button className="btn w-100" type="submit">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Header Sec */}
      <Footer />
      {/* Header Sec */}
    </>
  );
};

export default ForgotPass;
