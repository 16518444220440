import React from "react";
import { InnerBannerImg } from "../../constant/Index";

const InnerBanner = () => {
	let currentUrl = window.location.href.split("/");
	return (
		<>
			<section className="universal_banner">
				<div className="container-fluid p-0">
					<div className="banner-content">
						<div className="banner_image">
							<figure>
								<img src={InnerBannerImg} className="img-fluid" />
							</figure>
						</div>
						<div className="page-title">
							<h2 className="title">{currentUrl[3].split("#")[0]}</h2>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default InnerBanner;
