import React, { useEffect } from "react";
import Slider from "react-slick";
import { banimage } from "../../constant/Index";
import { useNavigate } from "react-router-dom";
import { fetchBanner } from "../../store/Banner/bannerSlice";
import { useSelector, useDispatch } from "react-redux";

const Banner = () => {
  const dispatch = useDispatch();
  const banner = useSelector((state) => state.banner);
  useEffect(() => {
    dispatch(fetchBanner());
  }, []);
  let navigate = useNavigate();
  //   Banner SLider Start
  const banSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  //   Banner SLider End

  return (
    <>
      {/* <!-- banner section starts here --> */}

     <div className="for-some-dots">
	 <Slider {...banSlider} arrows={false}>
        {banner?.bannerdata?.map((slide, index) => {
          return (
            <>
              <section key={index} className="banner">
                <div className="container">
                  <div className="banner-slider">
                    <div className="banner-slide-wrapper">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="banner-content-wrapper wow animate__animated animate__fadeInLeft">
                            <h5>{slide?.title}</h5>
                            <h1>{slide?.sub_title}</h1>
                            <p>{slide?.description}</p>
                            <button
                              onClick={() => navigate("/shop")}
                              className="btn btnColor"
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="banner-img-wrapper">
                            <figure>
                              <img
                                src={slide?.photo}
                                className="wow animate__animated animate__zoomIn img-fluid"
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        })}
      </Slider>

	 </div>
    </>
  );
};

export default Banner;
