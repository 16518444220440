import "./App.css";
import PublicRoutes from "./routes/PublicRoutes";
import {
	AddCartGetAll,
	fetchProductCategory,
	fetchWishlist,
} from "./store/productSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchPolicies } from "./store/SiteSetting/siteSettingSlice";

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchWishlist());
		dispatch(fetchProductCategory());
		dispatch(fetchPolicies());
	}, []);

	return (
		<>
			<PublicRoutes />
		</>
	);
}

export default App;
