import React from "react";
import { useSelector } from "react-redux";
import { ProfileDp } from "../../constant/Index";

const BasicInfo = (props) => {
	const { user } = useSelector((state) => state.auth);
	return (
		<>
			<div className="pro-detail-wrapper">
				<div className="profile-content-heading">
					<h4>Basic Information</h4>
				</div>
				<div className="profile-info-box-wrapper">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="dp-box-wrapper">
								<div className="profile-box-img-wrapper">
									<img src={user?.image} className="img-fluid" alt="" />
								</div>
								<div className="profile-box-content-wrapper">
									<div className="form-group ml-4">
										<label htmlFor="Photo">Photo</label>
										<h6>Upload / Change Photo</h6>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 text-right">
							<button
								onClick={() => {
									props.changePortion(2);
								}}
								className="edit-profile-btn"
							>
								Edit profile
							</button>
						</div>
					</div>

					<div className="box-fields-wrapper">
						<div className="form-group row">
							<div className="col-lg-6">
								<label htmlFor="">First Name</label>
								<input
									type="text"
									readOnly
									value={user.f_name}
									disabled
									className="form-control"
								/>
							</div>
							<div className="col-lg-6">
								<label htmlFor="">Last Name</label>
								<input
									type="text"
									readOnly
									value={user.l_name}
									disabled
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-lg-6">
								<label htmlFor="">Gender</label>
								<input
									type="text"
									readOnly
									value={user.gender ? user.gender : "Male"}
									disabled
									className="form-control"
								/>
							</div>
							<div className="col-lg-6">
								<label htmlFor="">Email</label>
								<input
									type="email"
									readOnly
									value={user.email}
									disabled
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-lg-6">
								<label htmlFor="">Phone</label>
								<input
									type="text"
									readOnly
									value={user.phone}
									disabled
									className="form-control"
								/>
							</div>
							<div className="col-lg-6">
								<label className="d-block" htmlFor="">
									Password
								</label>
								{/* <input type="password" value="**********" disabled /> */}
								<button
									onClick={() => {
										props.changePortion(3);
									}}
									className="change-password-btn"
								>
									Change Password
								</button>
							</div>
						</div>
					</div>
					<div className="box-fields-wrapper address-wrapper">
						<div className="form-group row">
							<div className="col-lg-8">
								<label htmlFor="" className="HomeClasSet">
									Home Address
								</label>
							</div>
							<div className="col-lg-4 text-right">
								<button
									onClick={() => {
										props.changePortion(4);
									}}
									className="change-address-btn"
								>
									Change Address
								</button>
							</div>
							<div className="col-lg-10">
								<textarea
									className="form-control textEditControll"
									disabled
									cols="30"
									rows="3"
									value={user?.street_address ? user?.street_address : ""}
								></textarea>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-lg-2">
								<label className="lbeltxt cityControl" htmlFor="City">
									City
								</label>
								&nbsp;
								<span className="styy">{user?.city ? user?.city : ""}</span>
								{/* <input
									className="inputtxt"
									type="text"
									value={user?.city ? user?.city : ""}
									disabled
								/> */}
							</div>
							<div className="col-lg-2">
								<label className="lbeltxt" htmlFor="State">
									State
								</label>
								&nbsp;
								<span className="styy">{user?.state ? user?.state : ""}</span>
								{/* <input
									className="inputtxt"
									type="text"
									value={user?.state ? user?.state : ""}
									disabled
								/> */}
							</div>
							<div className="col-lg-2">
								<label className="lbeltxt" htmlFor="Province">
									Province
								</label>
								&nbsp;
								<span className="styy">
									{user?.province ? user?.province : ""}
								</span>
								{/* <input
									className="inputtxt"
									type="text"
									value={user?.province ? user?.province : ""}
									disabled
								/> */}
							</div>
							<div className="col-lg-2">
								<label className="lbeltxt" htmlFor="Province">
									Country
								</label>
								&nbsp;
								<span className="styy">
									{user?.country ? user?.country : ""}
								</span>
								{/* <input
									className="inputtxt"
									type="text"
									value={user?.province ? user?.province : ""}
									disabled
								/> */}
							</div>
							<div className="col-lg-4 colss">
								<label className="lbeltxt" htmlFor="Zip-Code">
									Zip Code
								</label>
								&nbsp;
								<span className="styy">{user?.zip ? user?.zip : ""}</span>
								{/* <input
									className="inputtxt"
									type="text"
									value={user?.zip ? user?.zip : ""}
									disabled
								/> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BasicInfo;
