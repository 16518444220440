import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

const initialState = {
	blogData: [],
	latestBlog: [],
	blogDetail: [],
	isSuccess: false,
	isLoading: false,
	message: "",
};

export const fetchAllBlogs = createAsyncThunk(
	"blogs/all",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/blogs",
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

export const fetchLatestBlogs = createAsyncThunk(
	"blogs/latest",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/blogs/latest",
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

export const fetchBlogDetail = createAsyncThunk(
	"blog/detail",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/blogs/show/${arg}`,
			);
			return data?.response?.data;
		} catch (error) {
			rejectWithValue(error?.response?.data);
		}
	},
);

export const blogComment = createAsyncThunk(
	"blog/comment",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(
				"https://nutirents.developer-ourbase-camp.com/api/v1/blogs/postcomment",
				arg,
			);
			toast.success(data?.message);
			return data?.response?.data;
		} catch (error) {
			rejectWithValue(error?.response?.data);
		}
	},
);

const blogSlice = createSlice({
	name: "blogs",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllBlogs.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchAllBlogs.fulfilled, (state, action) => {
				state.isLoading = false;
				state.blogData = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchAllBlogs.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchLatestBlogs.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchLatestBlogs.fulfilled, (state, action) => {
				state.isLoading = false;
				state.latestBlog = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchLatestBlogs.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchBlogDetail.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchBlogDetail.fulfilled, (state, action) => {
				state.isLoading = false;
				state.blogDetail = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchBlogDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(blogComment.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(blogComment.fulfilled, (state, action) => {
				state.isLoading = false;
				state.blogDetail.comment = [
					...state.blogDetail.comment,
					action.payload,
				];
				state.isSuccess = true;
			})
			.addCase(blogComment.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			});
	},
});

export default blogSlice.reducer;
