import React, { useEffect, useState } from "react";
import "../../assets/css/cart.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { add, decreasedCart, remove } from "../../store/cartSlice";
import currency from "currency-formatter";
import {
	AddCartGetAll,
	DeleteItem,
	UpdatedCart,
} from "../../store/productSlice";

const Cart = () => {
	const isLogin = localStorage.getItem("Token");
	// const { user } = useSelector((state) => state.auth);
	const { Cart, UpdatedsCart, DeletedCart } = useSelector(
		(state) => state.product,
	);
	const [subTotal, setSubTotal] = useState(0);
	const [counter, setCounter] = useState();

	const dispatch = useDispatch();

	const handleRemove = (id) => {
		let data = {
			key: id,
		};
		dispatch(DeleteItem(data));
		dispatch(AddCartGetAll());
	};

	const handleDecreasedQuantity = (id, qty) => {
		const newQty = qty - 1;
		if (newQty < 1) {
			let data = {
				key: id,
			};
			dispatch(DeleteItem(data));
			return;
		}
		let data = {
			key: id,
			quantity: newQty,
		};
		dispatch(UpdatedCart(data));
	};

	const handleIncrementQuantity = (id, qty) => {
		const newQty = qty + 1;
		let data = {
			key: id,
			quantity: newQty,
		};
		dispatch(UpdatedCart(data));
	};

	useEffect(() => {
		dispatch(AddCartGetAll());
	}, [UpdatedsCart, DeletedCart]);

	const TotalAmount = () => {
		let i;
		let total = 0;
		for (i = 0; i < Cart?.length; i++) {
			total =
				total +
				Math.round(
					(Cart[i]?.price - Cart[i]?.discount) * Cart[i]?.quantity +
						Cart[i]?.tax * Cart[i]?.quantity,
				);
		}

		setSubTotal(total);
	};

	useEffect(() => {
		TotalAmount();
	}, [Cart]);

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Cart Sec */}
			{Cart?.length === 0 ? (
				<div className="EmptyCart">
					<div className="heading_wrapper">
						<h2>Your Cart Is Empty</h2>
					</div>
					<div className="redirect">
						<Link to="/shop">Back to shop</Link>
					</div>
				</div>
			) : (
				<section className="cart">
					<div className="container">
						<div className="row pb-5">
							<div className="col-lg-12">
								<div className="cart-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th scope="col"></th>
													<th scope="col">Product</th>
													<th scope="col">Product Name</th>
													<th scope="col">Unit Price</th>
													<th scope="col">Quantity</th>
													<th scope="col">Tax</th>
													<th scope="col">Sub Total</th>
												</tr>
											</thead>
											<tbody>
												{Cart?.map((product, i) => (
													<tr key={i}>
														<td className="close-row">
															<button
																onClick={() => handleRemove(product?.id)}
																className="removeBtn"
															>
																<i className="fa fa-close"></i>
															</button>
														</td>
														<td className="pro-img">
															<div className="img-wrapper">
																<img
																	src={product?.thumbnail}
																	className="img-fluid"
																	alt=""
																/>
															</div>
														</td>
														<td className="pro-name">{product?.name}</td>
														<td className="total-price">
															{/* $
															{product?.discount
																? Math.round(product?.price - product?.discount)
																: product?.price} */}
															{
																currency
																	.format(
																		product?.discount
																			? Math.round(
																					product?.price - product?.discount,
																			  )
																			: product?.price,
																		{
																			code: "GBP",
																		},
																	)
																	.split(".00")[0]
															}
														</td>
														<td>
															<div className="number">
																<button
																	onClick={() =>
																		handleIncrementQuantity(
																			product.id,
																			product.quantity,
																		)
																	}
																	className="plus quantity disabled"
																	type="button"
																	// disabled={
																	// 	product.quantity >= product.current_stock
																	// }
																>
																	+
																</button>
																<input type="text" value={product?.quantity} />
																<button
																	onClick={() =>
																		handleDecreasedQuantity(
																			product.id,
																			product.quantity,
																		)
																	}
																	className="minus quantity"
																	type="button"
																></button>
															</div>
														</td>
														<td>
															<div className="tax">
																{/* $ {product?.tax} */}
																{
																	currency
																		.format(product?.tax, {
																			code: "GBP",
																		})
																		.split(".00")[0]
																}
															</div>
														</td>
														<td className="sub-total">
															{/* $
															{product?.discount
																? Math.round(
																		(product?.price - product?.discount) *
																			product.quantity +
																			product?.tax * product.quantity,
																  )
																: Math.round(
																		product?.price * product.quantity +
																			product?.tax * product.quantity,
																  )} */}
															{
																currency
																	.format(
																		product?.discount
																			? (product?.price - product?.discount) *
																					product.quantity +
																					product?.tax * product.quantity
																			: product?.price * product.quantity +
																					product?.tax * product.quantity,

																		{
																			code: "GBP",
																		},
																	)
																	.split(".00")[0]
															}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									{/* <div className="button-group text-right">
                  <button className="btn">Update Cart</button>
                </div> */}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6"></div>
							<div className="col-lg-6">
								<div className="cart-t-tal-wrapper">
									<div className="cart-header">
										<h5 className="text-center">Cart Total</h5>
									</div>
									{/* <div className="cart-content-wrapper">
                    <ul>
                      <li>
                        <span className="property">Subtotal :</span>
                        <span className="value">$ 202</span>
                      </li>
                      <li>
                        <span className="property">Shipping Cost :</span>
                        <span className="value">$ 15.00</span>
                      </li>
                      <li>
                        <span className="property">Disscount Code :</span>
                        <span className="value">$ - 10.00</span>
                      </li>
                    </ul>
                  </div> */}
									<div className="cart-footer-wrapper">
										<ul>
											<li>
												<span className="property">Total Amount :</span>
												<span className="value">
													{/* ${Math.round(subTotal) */}
													{
														currency
															.format(subTotal, {
																code: "GBP",
															})
															.split(".00")[0]
													}
												</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="button-group-total d-flex flex-end pt-4 LoginCartBtn">
									{isLogin ? (
										<Link to="/checkout" className="btn pro-shop">
											Proceed To checkout
										</Link>
									) : (
										<Link to="/signin" className="btn pro-shop">
											Login
										</Link>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{/* Cart Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Cart;
