import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../../assets/css/blog-detailed.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
	BlogDesImg,
	BlogDesImg2,
	BlogDetailImg,
	QuoteImg1,
	QuoteImg2,
	UserComment1,
	UserComment2,
	UserComment3,
} from "../../constant/Index";
import { fetchBlogDetail } from "../../store/Blogs/blogSlice";
import { blogComment } from "../../store/Blogs/blogSlice";

const BlogDetail = () => {
	let { id } = useParams();
	let dispatch = useDispatch();
	const blogs = useSelector((state) => state.blogs);
	const { user } = useSelector((state) => state.auth);
	useEffect(() => {
		dispatch(fetchBlogDetail(id));
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");

	const formData = new FormData();
	formData.set("blog_id", id);
	formData.set("name", user?.f_name ? user?.f_name : name);
	formData.set("email", user?.email ? user?.email : email);
	formData.set("comment", comment);

	const handleComment = (e) => {
		e.preventDefault();
		dispatch(blogComment(formData));
	};

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Blog Detail Sec */}
			<section className="detailed">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 ">
							<div className="blog-deatiled-img-wrapper pt-5">
								<figure>
									<img
										src={blogs?.blogDetail?.image}
										className="img-fluid detailed-img"
										alt=""
									/>
								</figure>
								<div className="blo-date-and-authar-wrapper">
									<ul>
										<li>
											<Link to="#">
												<i className="fa fa-calendar"></i>{" "}
												{blogs?.blogDetail?.created_at}
											</Link>
										</li>
										<li>
											<Link to="#">
												<i className="fa fa-user"></i> By Admin
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="blog-description">
								<p
									dangerouslySetInnerHTML={{
										__html: blogs?.blogDetail?.content,
									}}
								></p>
								{/* <ul>
                  <li>
                    Koluptate velit cillum dolre fugiat nula pariatur. Excepteur
                    anim idet laborumed perspiciat und omnis iste natus
                    goluptatem
                  </li>
                  <li>
                    Koluptate velit cillum dolre fugiat nula pariatur. Excepteur
                    anim idet
                  </li>
                </ul> */}
							</div>
							{/* <div className="quotes-wrapper">
								<div className="row">
									<div className="col-lg-12">
										<div className="quotation-wrapper">
											<div className="quote1 align-self-start">
												<img src={QuoteImg1} alt="" />
												<span className="d-md-none">"</span>
											</div>
											<div className="quotation">
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim ad minim veniam, quis
													nostrud exercitation ullamco laboris nisi ut aliquip
													ex ea commodo consequat. Duis aute irure dolor in
													reprehenderit in voluptate velit esse cillum dolore eu
													fugiat nulla pariatur. Excepteur sint occaecat
													cupidatat non proident, sunt in culpa qui officia
													deserunt mollit anim id est laborum.
												</p>
											</div>
											<div className="quote2 align-self-end">
												<img src={QuoteImg2} alt="" />
												<span className="d-md-none">"</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="more-des-wrapper">
								<p>
									Koluptate velit cillum dolre fugiat nula pariatur. Excepteur
									anim idet laborumed perspiciat und omnis iste natus goluptatem
									acusantium dolore mque lorem ipsum dolor sit amet cons ectetur
									adipisicing elit sed incididunt. Lorem ipsum dolor sit amet
									consectetur adipsicing elit sed do eiusmod tempor incididunt
									labore et dolore magna aliqua. Koluptate velit cillum dolre
									fugiat nula pariatur. Excepteur anim idet laborumed perspiciat
									und omnis iste natus goluptatem acusantium dolore mque lorem
									ipsum dolor
								</p>
								<div className="row">
									<div className="col-lg-8">
										<p>
											Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit sed
											do eiusmod tempor incididunt labore et dolore magna
											aliqua. Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit sed
											do eiusmod tempor incididunt labore et dolore magna
											aliqua. Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit sed
											do eiusmod tempor incididunt labore et dolore magna
											aliqua. Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit
										</p>
									</div>
									<div className="col-lg-4">
										<figure>
											<img src={BlogDesImg} className="img-fluid" alt="" />
										</figure>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<p className="kil">
											There are many variations of passages of Lorem Ipsum
										</p>
										<ul>
											<li>
												Koluptate velit cillum dolre fugiat nula pariatur.
												Excepteur anim idet laborumed perspiciat und omnis iste
												natus goluptatem
											</li>
											<li>
												Koluptate velit cillum dolre fugiat nula pariatur.
												Excepteur anim idet
											</li>
											<li>
												Koluptate velit cillum dolre fugiat nula pariatur.
												Excepteur anim idet laborumed perspiciat und omnis iste
												natus goluptatem
											</li>
											<li>
												Koluptate velit cillum dolre fugiat nula pariatur.
												Excepteur anim idet
											</li>
										</ul>
									</div>
								</div>
								<div className="row py-3 pb-5">
									<div className="col-lg-6">
										<figure>
											<img src={BlogDesImg2} className="img-fluid" alt="" />
										</figure>
									</div>
									<div className="col-lg-6 align-self-center">
										<div className="content-wrapper">
											<h3>Lorem Ipsum is simply dummy text</h3>
											<p>
												Koluptate velit cillum dolre fugiat nula pariatur.
												Excepteur anim idet laborumed perspiciat und omnis iste
												natus goluptatem acusantium dolore mque lorem ipsum
												dolor sit amet cons ectetur adipisicing elit sed
												incididunt. Lorem ipsum dolor sit amet consectetur
												adipsicing elit sed do eiusmod tempor incididunt labore
												et dolore magna aliqua. Koluptate velit cillum dolre
												fugiat nula pariatur. Excepteur anim idet laborumed
												perspiciat und omnis iste natus goluptatem acusantium
												dolore mque lorem ipsum dolor sit amet cons ectetur
												adipisicing elit sed incididunt. Lorem ipsum dolor sit
												amet consectetur adipsicing elit sed do eiusmod tempor
												incididunt labore et dolore magna aliqua. Koluptate
												velit cillum dolre fugiat nula pariatur. Excepteur anim
												idet laborumed perspiciat und omnis iste natus
											</p>
										</div>
									</div>
								</div>
							</div> */}
							<div className="blog-replies-wrapper">
								<div className="row">
									<div className="col-sm-12 pb-5">
										<h3>
											{blogs?.blogDetail?.comment?.length} Replies to “
											<span>{blogs?.blogDetail?.title}</span>”
										</h3>
									</div>
								</div>
								{blogs?.blogDetail?.comment?.map((comments, i) => (
									<div key={i} className="row">
										<div className="col-lg-12">
											<div className="user-content-wrapper">
												<h5 className="replied-user-name">{comments?.name}</h5>
												<h6 className="comment-date">{comments?.created_at}</h6>
												<p className="comment-content">{comments?.comment}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Detail Sec */}

			{/* Quote Sec Start Here */}
			<section className="quote">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="form-heading">
								<h3>Leave A Comment</h3>
							</div>
							<form onSubmit={handleComment} style={{ display: "block" }}>
								<div className="form-group row">
									<div className="col-md-6">
										<label htmlFor="">Your Name</label>
										<input
											value={user?.f_name ? user?.f_name : name}
											required
											onChange={(e) => setName(e.target.value)}
											type="text"
											className="form-control"
											placeholder="John Doe"
										/>
									</div>
									{console.log(user)}
									<div className="col-md-6">
										<label htmlFor="">Email</label>
										<input
											type="email"
											value={user?.email ? user?.email : email}
											required
											onChange={(e) => setEmail(e.target.value)}
											className="form-control"
											placeholder="JohnDoe@gmail.com"
										/>
									</div>
									<div className="col-md-6">
										<input
											hidden
											type="number"
											defaultValue={id}
											required
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group pt-4">
									<label htmlFor="">Type Your Comment</label>
									<textarea
										value={comment}
										required
										onChange={(e) => setComment(e.target.value)}
										className="form-control"
										placeholder="Type here"
										cols="30"
										rows="10"
									></textarea>
								</div>
								<div className="form-group row pt-4">
									<div className="col-lg-8">
										<div className="form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="exampleCheck1"
											/>
											<label
												className="form-check-label"
												htmlFor="exampleCheck1"
											>
												Save my name, email, and website in this browser for the
												next time I comment.
											</label>
										</div>
									</div>
									<div className="col-lg-4 text-right">
										<button className="btn" type="submit">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* Quote Sec End Here */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default BlogDetail;
