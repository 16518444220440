import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetCoupon } from "../../store/productSlice";
import currency from "currency-formatter";

const OrderSummary = () => {
	const dispatch = useDispatch();
	const [coup, setCoup] = useState();
	const { Coupon, message, GetShippingAddress, deliverId, Cart } = useSelector(
		(state) => state.product,
	);

	let SendPrice = Cart?.map((item) => {
		return item?.price;
	}).reduce((a, b) => a + b, 0);

	const [subTotal, setSubTotal] = useState(0);
	const [del, setDel] = useState(20);
	const TotalAmount = () => {
		let i;
		let total = 0;
		for (i = 0; i < Cart?.length; i++) {
			total =
				total +
				Math.round(
					(Cart[i]?.price - Cart[i]?.discount) * Cart[i]?.quantity +
						Cart[i]?.tax * Cart[i]?.quantity,
				);
		}
		setSubTotal(total);
	};

	// let checkCondition =
	// 	subTotal > Coupon?.min_purchase ? subTotal - Coupon.discount_price : 0;

	// 	let checkit = Coupon.discount_price ? subTotal - Coupon.discount_price : 0;

	// let subT = checkCondition + (shippingBill == "NaN" ? 0 : shippingBill);

	// price - discount_price

	let keys = GetShippingAddress?.map((item) => item).filter((item) =>
		item.id == deliverId ? item : "",
	);
	let shippingBill = keys?.length == [] ? 0 : keys[0]?.cost;

	let subT = Coupon?.discount_price
		? subTotal - Coupon?.discount_price
		: subTotal;
	let subT2 = subT + shippingBill;

	useEffect(() => {
		TotalAmount();
	}, [Cart]);

	const HandleSendCode = (e) => {
		e.preventDefault();
		localStorage.setItem("Coupon", coup);
		let form = new FormData();
		form.set("code", coup);
		form.set("total_amount ", SendPrice);
		dispatch(GetCoupon(form));
	};

	// let shippingBill = GetShippingAddress.map((item) => item).filter((item) =>
	// 	item.id == shipping ? item : null,
	// );

	return (
		<>
			<div className="second-top-bar">
				<div className="title pb-4">
					<h3>Order Summery</h3>
				</div>
				<label htmlFor="Enter Voucher Code : ">Enter Voucher Code :</label>
				<div className="form-group">
					<input
						type="text"
						className="form-control"
						value={coup}
						onChange={(e) => setCoup(e.target.value)}
					/>
					<button type="button" onClick={HandleSendCode}>
						SUBMIT <img src="img/right-arrow.svg" alt="" />
					</button>
				</div>
			</div>
			<div className="cart-product-detail">
				<div className="title">
					<h3>Products Details</h3>
				</div>
				<div className="checkout-products">
					{Cart?.map((proOrder, index) => {
						return (
							<div className="products br-right">
								<div className="abt-product">
									<div className="img-box">
										<figure>
											<img src={proOrder?.thumbnail} alt="123" />
										</figure>
									</div>
									<div className="pro-dtl">
										<h5 className="name">{proOrder?.name}</h5>
										<ul className="variations">
											<li>Items ({proOrder?.quantity})</li>
										</ul>
									</div>
								</div>
								<div className="product-price">
									<h4 className="price">
										{/* ${proOrder?.price} */}
										{
											currency
												.format(proOrder?.price, {
													code: "GBP",
												})
												.split(".00")[0]
										}
									</h4>
								</div>
							</div>
						);
					})}
				</div>
				<div className="pricing-details">
					<div className="item-total">
						<h4 className="property">Item Total :</h4>
						<h4 className="value">
							{/* ${subTotal} */}
							{
								currency
									.format(subTotal, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>
					<div className="item-total">
						<h4 className="property">Delivery charges</h4>
						<h4 className="value">
							{/* ${shippingBill ? shippingBill : 0} */}
							{
								currency
									.format(shippingBill ? shippingBill : 0, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>
					{/* <div className="item-total">
            <h4 className="property">Loyalty Points Applied :</h4>
            <h4 className="value">-$5</h4>
          </div> */}
					<div className="item-total">
						<h4 className="property">Promo Code Applied :</h4>
						<h4 className="value">
							{/* ${Coupon?.discount_price ? Coupon?.discount_price : 0} */}
							{
								currency
									.format(Coupon?.discount_price ? Coupon?.discount_price : 0, {
										code: "GBP",
									})
									.split(".00")[0]
							}
						</h4>
					</div>

					{/* <p style={{ color: "red", fontWeigth: 400 }}>
						{subTotal  < Coupon?.min_purchase
							? `This order Total Amount is greater than minimum purchase amount ${Coupon?.min_purchase}`
							: null}
					</p> */}
				</div>

				<div className="order-total">
					<h3 className="property">Order Total</h3>
					<h3 className="value">
						{/* ${Math.round(subT2)} */}
						{
							currency
								.format(Math.round(subT2), {
									code: "GBP",
								})
								.split(".00")[0]
						}
					</h3>

					{/* let keys = GetShippingAddress?.map((item) => item).filter((item) =>
		item.id == deliverId ? item : "",
	);
	let shippingBill = keys && keys[0]?.cost;
	let subT = checkCondition + (shippingBill == "NaN" ? 0 : shippingBill); */}
				</div>
			</div>
		</>
	);
};

export default OrderSummary;
