import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "animate.css";
import { routes } from "./index";
import Component from "../components/Protected";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PublicRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<ToastContainer />
				<Routes>
					{routes.map((val, key) => (
						<Route
							key={key}
							exact
							path={val?.path}
							element={
								val?.protected ? (
									<Component>{val?.element}</Component>
								) : (
									val?.element
								)
							}
						/>
					))}
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default PublicRoutes;
