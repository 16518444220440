import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StarsRating from "stars-rating";
import { ProductComment } from "../../store/productSlice";
import Rating from "@mui/material/Rating";

const AddReview = (props) => {
	const isLogin = localStorage.getItem("Token");
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const [rating, setRating] = useState("");

	const ratingChanged = () => {
		setRating();
	};

	useEffect(() => {
		setName(user?.f_name);
		setEmail(user?.email);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const form_data = new FormData();
		form_data.set("product_id", props.product_id);
		form_data.set("name", name);
		form_data.set("email", email);
		form_data.set("rating", rating);
		form_data.set("comment", comment);
		dispatch(ProductComment(form_data));
		console.log(email, name);
		// setInterval(() => {
		//   setComment("");
		//   setRating("");
		// }, 5000);
	};
	return (
		<>
			<div className="col-lg-12">
				<div className="rev-wrapper">
					<h4 className="mb-4">Add Review</h4>

					<form onSubmit={handleSubmit}>
						<div className="form-group row">
							<div className="col-lg-4">
								<input
									type="text"
									value={name}
									className="form-control"
									placeholder="Name"
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="col-lg-4">
								<input
									value={email}
									type="email"
									className="form-control"
									placeholder="Email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="col-lg-4 align-item-center">
								<input
									type="text"
									className="form-control"
									placeholder="Rating"
									readOnly
								/>
								<div className="rating">
									{/* <StarsRating
										count={5}

										onChange={ratingChanged}
										size={24}
									/> */}
									<Rating
										name="simple-controlled"
										color2={"#ffd700"}
										value={rating}
										onChange={(event, newValue) => {
											setRating(newValue);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<textarea
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								className="form-control"
								placeholder="Type here"
								cols="30"
								rows="10"
							></textarea>
						</div>
						<div className="form-group row">
							<div className="col-lg-8">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										id="defaultCheck1"
									/>
									<label className="form-check-label" htmlFor="defaultCheck1">
										By using this form you agree with the storage and handling
										of your data by this website.
									</label>
								</div>
							</div>
							<div className="col-lg-4 text-right">
								{isLogin ? (
									<>
										<button className="btn" type="submit">
											Submit
										</button>
									</>
								) : (
									<>
										<Link to="/signin" className="btn">
											Login
										</Link>
									</>
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddReview;
