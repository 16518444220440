import Blogs from "../pages/Blog/Blogs";
import NotFound from "../components/NotFound.js/NotFound";
import AboutUs from "../pages/AboutUs/AboutUs";
import CreatePass from "../pages/Auth/CreatePass";
import ForgotPass from "../pages/Auth/ForgotPass";
import Otp from "../pages/Auth/Otp";
import Signin from "../pages/Auth/Signin";
import Signup from "../pages/Auth/Signup";
import BlogDetail from "../pages/Blog/BlogDetail";
import Cart from "../pages/Cart/Cart";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import PirvacyPolicy from "../pages/PolicyPages/PrivacyPolicy";
import RefundPolicy from "../pages/PolicyPages/RefundPolicy";
import TermsCondition from "../pages/PolicyPages/TermsCondition";
import Profile from "../pages/Profile/Profile";
import Shop from "../pages/Shop/Shop";
import ProductDetail from "../pages/ProductDetail/ProductDetail";
import Checkout from "../pages/Checkout/Checkout";
import AllCategories from "../pages/AllCategories/AllCategories";
import SubCategory from "../pages/SubCategory/SubCategory";
import ChildCategory from "../pages/AllCategories/ChildCategory";
import Thankyou from "../pages/Checkout/Thankyou";
import DeliveryInfo from "../pages/PolicyPages/DeliveryInfo";
import CookiePolicy from "../pages/PolicyPages/CookiePolicy";

//  (token) ?  / :

export const routes = [
	{ path: "/", element: <Home />, protected: false },
	{ path: "/about-us", element: <AboutUs />, protected: false },
	{ path: "/shop/*", element: <Shop />, protected: false },
	{ path: "/product-detail/:id", element: <ProductDetail />, protected: false },
	{ path: "/blogs", element: <Blogs />, protected: false },
	{ path: "/blog-detail/:id", element: <BlogDetail />, protected: false },
	{ path: "/contact-us", element: <Contact />, protected: false },
	{ path: "/cart", element: <Cart />, protected: false },
	{ path: "/checkout", element: <Checkout />, protected: true },
	{ path: "/thankyou", element: <Thankyou />, protected: true },
	{ path: "/signin", element: <Signin />, protected: false },
	{ path: "/signup", element: <Signup />, protected: false },
	{ path: "/forgot-password", element: <ForgotPass />, protected: false },
	{ path: "/create-pass", element: <CreatePass />, protected: false },
	{ path: "/otp", element: <Otp />, protected: false },
	{ path: "/privacy-policy", element: <PirvacyPolicy />, protected: false },
	{ path: "/refund-policy", element: <RefundPolicy />, protected: false },
	{ path: "/cookie-policy", element: <CookiePolicy />, protected: false },
	{ path: "/deliver-info", element: <DeliveryInfo />, protected: false },
	{ path: "/all-categories", element: <AllCategories />, protected: false },
	{ path: "/sub-category", element: <SubCategory />, protected: false },
	{ path: "/child-categories", element: <ChildCategory />, protected: false },

	{
		path: "/terms-&-conditions",
		element: <TermsCondition />,
		protected: false,
	},
	{ path: "/profile", element: <Profile />, protected: true },
	{ path: "*", element: <NotFound />, protected: false },
];
