import React, { useEffect } from "react";
import "../../assets/css/terms.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPolicies } from "../../store/SiteSetting/siteSettingSlice";

const PirvacyPolicy = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.data);
	useEffect(() => {
		dispatch(fetchPolicies());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			<section className="terms">
				<div className="container">
					{/* <div className="heading">
            <h4>Privacy Policy</h4>
          </div> */}
					{data?.Alldata?.map((privacy, index) => (
						<div key={index} className="content_wrapper">
							<h3 className="title">Privacy Policy</h3>
							<p
								className="fw-400"
								dangerouslySetInnerHTML={{ __html: privacy?.privacy_policy }}
							/>
						</div>
					))}
				</div>
			</section>

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default PirvacyPolicy;
