import React from "react";
import {
	BestSellBg,
	BestSelldis,
	CateImg2,
	Card1,
	CardImg2,
} from "../../constant/Index";

function BestSeller() {
	return (
		<section className="BestSeller">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						{/* <div className="bestSellCard">
							<div className="bestTopSec">
								<img src={BestSellBg} alt="" />
							</div>
							<div className="cardTitleSection">
								<div className="dicountPrice">
									<div className="setDicAmount">
										<img src={BestSelldis} alt="" />
										<div className="priceDisSell">
											<h3>
												50%<span> OFF</span>
											</h3>
										</div>
									</div>
								</div>
								<div className="descBestsell">
									<h4>Buy 1 get 1 free</h4>
									<p>Lorem Ipsum is simple dummy text</p>
								</div>
								<div className="productImageBestsell">
									<img src={CateImg2} alt="" />
								</div>
							</div>
						</div> */}
						<div class="card bestSellCard">
							<img src={BestSellBg} alt="" />
							<div class="card-body">
								<div className="dicountPrice">
									<div className="setDicAmount">
										<img src={BestSelldis} alt="" />
										<div className="priceDisSell">
											<h3>
												50%<span> OFF</span>
											</h3>
										</div>
									</div>
								</div>
								<div className="card-text">
									<h4>Buy 1 get 1 free</h4>
									<p>Lorem Ipsum is simple dummy text</p>
								</div>
								<div className="productImageBestsell">
									<img src={CateImg2} alt="" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="row">
							<div class="card secondCard">
								<img src={Card1} alt="" />
								<div class="card-body">
									<div className="dicountPrice">
										<div className="setDicAmount">
											<img src={BestSelldis} alt="" />
											<div className="priceDisSell">
												<h3>
													50%<span> OFF</span>
												</h3>
											</div>
										</div>
									</div>
									<div className="card-text">
										<h4>Buy 1 get 1 free</h4>
										<p>Lorem Ipsum is simple dummy text</p>
									</div>
									<div className="productImageBestsell">
										<img src={CardImg2} alt="" />
									</div>
								</div>
							</div>
							<div className="col-lg-6"></div>
							<div className="col-lg-6"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default BestSeller;
