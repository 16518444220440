import React, { useEffect } from "react";
import "../../assets/css/blog.css";
// Component Import
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { BlogsImg1, BlogsImg2, BlogsImg3 } from "../../constant/Index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBlogs } from "../../store/Blogs/blogSlice";

const Blogs = () => {
	const allBlogs = useSelector((state) => state.blogs);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAllBlogs());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Blogs Sec */}
			<section className="blog_sec">
				<div className="container">
					<div className="row">
						{allBlogs?.blogData?.map((blogss, i) => (
							<div key={i} className="col-lg-4 col-md-6">
								<div className="blog_box">
									<Link to={`/blog-detail/${blogss.id}`}>
										<div className="picture_box">
											<div className="img_box">
												<figure>
													<img src={blogss?.image} className="img-fluid" />
												</figure>
											</div>
											<div className="hover_dv"></div>
										</div>
									</Link>
									<div className="content_box">
										<div className="date">
											<h5>{blogss?.created_at}</h5>
										</div>
										<div className="name">
											<Link to={`/blog-detail/${blogss.id}`}>
												<h4>{blogss?.title}</h4>
											</Link>
										</div>
										{/* <p>{blogss?.content}</p> */}
										<p
											className="blog-des"
											dangerouslySetInnerHTML={{
												__html: blogss?.content.slice(0, 200),
											}}
										/>
										<div className="button-group">
											<Link to={`/blog-detail/${blogss.id}`}>
												Read more
												<span className="icon">
													<i className="fa fa-plus" aria-hidden="true"></i>
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Blogs Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Blogs;
