import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/about.css";
import Faqs from "../../components/Faqs/Faqs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
	AboutPageImg,
	FaqImg,
	MileStoneImg1,
	MileStoneImg2,
	MileStoneImg3,
	MileStoneImg4,
	VideoPlyBtn,
	VideoPreview,
} from "../../constant/Index";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPolicies } from "../../store/SiteSetting/siteSettingSlice";

const AboutUs = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.data);
	useEffect(() => {
		dispatch(fetchPolicies());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Inner Banner */}
			<InnerBanner />
			{/* Inner Banner */}

			{/* About Sec */}
			<section className="about_sec">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="content_wrapper">
								<div className="heading_wrapper">
									<h5 className="sub-title">About Us</h5>
								</div>
								<div className="content">
									<p
										className="grey"
										dangerouslySetInnerHTML={{
											__html: data?.Alldata[0]?.about_us,
										}}
									/>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="content_wrapper" id="our_mission">
											<div className="heading_wrapper">
												<h5 className="sub-title">Our Mission</h5>
											</div>
											<div className="content">
												<p
													className="grey"
													dangerouslySetInnerHTML={{
														__html: data?.Alldata[0]?.our_mission,
													}}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="content_wrapper" id="our_vission">
											<div className="heading_wrapper">
												<h5 className="sub-title">Our Vision</h5>
											</div>
											<div className="content">
												<p
													className="grey"
													dangerouslySetInnerHTML={{
														__html: data?.Alldata[0]?.our_vision,
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="button-group">
									<Link to="/shop" className="btn">
										View All Products
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="picture_box">
								<div className="img_box">
									<figure>
										<img
											src={data?.Alldata[0]?.about_us_image}
											className="img-fluid"
											alt=""
										/>
									</figure>
								</div>
								<div className="total_customer">
									<h2>2.1 M</h2>
									<h5>
										Customer <br />
										Served
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About Sec */}

			{/* Our Mission */}
			{/* <section className="about_sec">
				<div className="container">
					<div className="row align-items-center">
						
					</div>
				</div>
			</section> */}
			{/* Our Mission */}

			{/* Store Sec */}
			<section className="store_sec text-white" id="counter">
				<div className="container">
					<div className="heading_wrapper">
						<h5>Video</h5>
						<h3 className="title">Checkout Our Store</h3>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="mile-box">
								<div className="icon_box">
									<figure>
										<img src={MileStoneImg1} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="content_box">
									<h5>Our Clients</h5>
									<h3>
										<span className="count">250</span>
										<span className="symbol">+</span>
									</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mile-box">
								<div className="icon_box">
									<figure>
										<img src={MileStoneImg2} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="content_box">
									<h5>Lorem ipsum</h5>
									<h3>
										<span className="count">350</span>
										<span className="symbol">+</span>
									</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mile-box">
								<div className="icon_box">
									<figure>
										<img src={MileStoneImg3} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="content_box">
									<h5>Happy Clients</h5>
									<h3>
										<span className="count">250</span>
										<span className="symbol">+</span>
									</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mile-box">
								<div className="icon_box">
									<figure>
										<img src={MileStoneImg4} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="content_box">
									<h5>Clients Reviews</h5>
									<h3>
										<span className="count">250</span>
										<span className="symbol">+</span>
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Store Sec */}

			{/* Video Sec */}
			<section className="video_sec">
				<div className="container">
					<div className="preview_img">
						<figure>
							<img src={VideoPreview} className="img-fluid" alt="" />
						</figure>
					</div>
					<div className="ply-btn">
						<Link to="#">
							<figure>
								<img src={VideoPlyBtn} className="img-fluid" alt="" />
							</figure>
						</Link>
					</div>
				</div>
			</section>
			{/* Video Sec */}

			{/* Faqs Sec */}
			<section className="faqs_sec">
				<div className="container">
					<div className="heading_wrapper">
						<h5 className="sub-title">FAQ'S</h5>
						<h3>Frequently Asked Question's</h3>
					</div>
					<div className="row align-items-center">
						<div className="col-md-5">
							<div className="img_box">
								<figure>
									<img src={FaqImg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-md-7">
							<div className="faqs_box">
								<div id="accordion">
									<Faqs faqlimit={4} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Faqs Sec */}

			{/* Header Sec */}
			<Footer />
			{/* Header Sec */}
		</>
	);
};

export default AboutUs;
