import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  bannerdata: [],
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const fetchBanner = createAsyncThunk(
  "banner/data",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "https://nutirents.developer-ourbase-camp.com/api/v1/banners?banner_type=main_banner"
      );
      return data.response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bannerdata = action.payload;
        state.isSuccess = true;
      })
      .addCase(fetchBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export default bannerSlice.reducer;
