import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import service from "../../store/auth/authService";

const Newsletter = () => {
	const dispatch = useDispatch();
	const [state2, setState2] = useState({
		email: "",
	});

	const HandleChange = (e) => {
		setState2((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let form_Data = new FormData();
		form_Data.set("email", state2?.email);
		dispatch(service.subscribeNewsletter(form_Data));
		setState2({ email: "" });
	};
	return (
		<>
			<section className="newsletter">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="row newsletter-bg">
								<div className="col-lg-5">
									<div className="content-wrapper">
										<h6>Newsletter</h6>
										<h3>Subscribe Our Newsletter</h3>
										<p>
											We're here to inform with business tactics need funding
											and which are.
										</p>
									</div>
								</div>
								<div className="col-lg-7">
									<form onSubmit={handleSubmit}>
										<div className="newsletter-input-wrapper">
											<input
												name="email"
												onChange={HandleChange}
												value={state2?.email}
												type="text"
												placeholder="Enter Your Email"
												className="form-control"
											/>
											<button type="submit" className="btn btnBlogs">
												Subscribe
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Newsletter;
