import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	ProBannerImg1,
	ProBannerImg2,
	ProBannerImg3,
	HerbalSupply,
} from "../../constant/Index";

import { useDispatch } from "react-redux";
import { SendCateNameLice } from "../../store/productSlice";

const ProductsBanner = () => {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const ProBannerData = [
		{
			id: 1,
			ProBannerTagline: "In Store Now",
			ProBannerName: "Get Set For Winter",
			ProBannerDesc:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
			ProBannerImg: ProBannerImg1,
		},
		{
			id: 2,
			ProBannerTagline: "New Product",
			ProBannerName: "Multivitamins",
			ProBannerImg: ProBannerImg2,
		},
		{
			id: 3,
			ProBannerTagline: "Sports",
			ProBannerName: "Sports Nutritions $ Accessories",
			ProBannerImg: ProBannerImg3,
		},
	];

	const HandleClicks = () => {
		dispatch(SendCateNameLice("sports"));
		navigate("/shop");
	};

	const HandleClicks2 = () => {
		dispatch(SendCateNameLice("multivitamins"));
		navigate("/shop");
	};

	return (
		<>
			{/* Product Banner Sec Start Here */}
			<section className="winter-sec">
				<div className="container">
					<div className="health_first_heading">
						<p className="Heading_pro mb-5">Sports Nutrition</p>
					</div>
					<div className="row">
						<div className="col-lg-7">
							<div className="winter-card-wrapper">
								<div className="card-content-wrapper">
									{/* <h6>{ProBannerData[0].ProBannerTagline}</h6>
									<h2>{ProBannerData[0].ProBannerName}</h2>
									<p>{ProBannerData[0].ProBannerDesc}</p> */}
									<h6>In Store Now</h6>
									<h2>
										Get Set For <br /> Winter
									</h2>
									<p>
										Lorem ipsum is simply dummy text of the <br />
										printing and typesetting industry.
									</p>
									<button
										onClick={() => navigate("/shop")}
										className="btn proBannerbtn btnColor"
									>
										Buy Now
									</button>
								</div>
								<div className="card-img-wrapper">
									<img
										src={ProBannerData[0].ProBannerImg}
										className="img-fluid"
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="sports-card-wrapper sport_wrapper">
								<div className="sport-content-wrapper">
									{/* <h6>{ProBannerData[1].ProBannerTagline}</h6>
									<h2>{ProBannerData[1].ProBannerName}</h2> */}
									<h6>Sports</h6>
									<h2>
										Sports Nutritions <br />$ Accessories
									</h2>
									<button
										type="button"
										className="btn btnColor2"
										onClick={HandleClicks2}
									>
										Buy Now
									</button>
								</div>
								<div className="sports-img-wrapper">
									<img
										src={ProBannerData[1].ProBannerImg}
										className="img-fluid"
										alt=""
									/>
								</div>
							</div>
							<div className="sports-card-wrapper herb">
								<div className="sports-img-wrapper">
									<img
										src={ProBannerData[2].ProBannerImg}
										className="img-fluid"
										alt=""
									/>
								</div>
								<div className="sport-content-wrapper">
									{/* <h6>{ProBannerData[2].ProBannerTagline}</h6>
									<h2>{ProBannerData[2].ProBannerName}</h2> */}
									<h6>New Product</h6>
									<h2>
										Herbal healthy <br /> Supplement
									</h2>
									<button
										type="button"
										className="btn btnColor2"
										onClick={HandleClicks}
									>
										Buy Now
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Product Banner Sec End Here */}
		</>
	);
};

export default ProductsBanner;
