import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProfileDp } from "../../constant/Index";
import service from "../../store/auth/authService";
import { resetProfile } from "../../store/auth/authSlice";
import { useDispatch } from "react-redux";

const EditProfile = (props) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [state, setState] = useState({
		f_name: "",
		l_name: "",
		phone: "",
		gender: "",
	});

	let { f_name, l_name, phone, gender } = state;

	useEffect(() => {
		setState(user);
	}, [user]);

	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState(user?.image);
	const [uploadLoading, setUploadLoading] = useState(false);

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const HandleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		let form_Data = new FormData();
		form_Data.set("f_name", f_name);
		form_Data.set("l_name", l_name);
		form_Data.set("phone", phone);
		form_Data.set("image", newFile);
		form_Data.set("gender", gender);
		dispatch(service.editProfileRequest(form_Data));
	};
	const options = [
		{ value: "", text: "--Choose an option--" },
		{ value: "Male", text: "Male" },
		{ value: "female", text: "Female" },
		{ value: "Other", text: "Other" },
	];
	return (
		<>
			<div className="pro-edit-wrapper">
				<div className="profile-content-heading">
					<h4>Edit Information</h4>
				</div>
				<div className="profile-info-box-wrapper">
					<div className="row align-items-center mb-5 pb-3">
						<div className="col-lg-8"></div>
						<div className="col-lg-4 text-right">
							<div className="processing-wrapper">
								<img src="img/processing.svg" alt="" />
							</div>
						</div>
					</div>

					<div className="box-fields-wrapper edit-fields">
						<form onSubmit={handleSubmit}>
							<div className="dp-box-wrapper">
								<div className="profile-box-img-wrapper">
									{/* <img src={user.image} className="img-fluid" alt="" /> */}
									{uploadLoading ? (
										<img
											src={fileupload}
											className="image-fluid image-width"
											alt=""
										/>
									) : (
										<img
											src={`${fileupload ? fileupload : user?.image}`}
											className="image-fluid image-width"
											alt=""
										/>
									)}
								</div>
								<div className="profile-box-content-wrapper">
									<div className="form-group ml-4">
										<h6>Jpeg or Png maximum size (5mb)</h6>
										{/* <!-- <input type="file"> --> */}
										<label className="labelBtn" htmlFor="yourBtn">
											Upload Photo
										</label>
									</div>
								</div>
							</div>
							<div
								style={{
									height: "0px",
									width: "0px",
								}}
								hidden
							>
								<input
									type="file"
									name="image"
									id="yourBtn"
									accept="image/*"
									onChange={handleImageUpload}
									multiple="false"
								/>
							</div>
							<div className="form-group row">
								<div className="col-lg-6">
									<input
										type="text"
										value={f_name}
										onChange={HandleChange}
										className="form-control"
										name="f_name"
										placeholder="First Name"
									/>
								</div>
								<div className="col-lg-6">
									<input
										type="text"
										value={l_name}
										name="l_name"
										onChange={HandleChange}
										placeholder="Last Name"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-6">
									<input
										type="tel"
										value={phone}
										name="phone"
										onChange={HandleChange}
										placeholder="Phone"
										className="form-control"
									/>
								</div>
								<div className="col-lg-6">
									{/* <select defaultValue={gender} className="form-control">
										<option value="">Gender</option>
										<option value="male">Male</option>
										<option value="Female">Female</option>
									</select> */}
									<select
										value={gender}
										onChange={HandleChange}
										className="form-control"
										name="gender"
									>
										{options.map((option) => (
											<option key={option.value} value={option.value}>
												{option.text}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-6">
									<input
										type="email"
										readOnly
										id="email"
										value={user?.email}
										placeholder="Email"
										className="form-control"
									/>
								</div>
								<div className="col-lg-6 text-right">
									<button
										onClick={() => {
											props.changePortion(1);
										}}
										className="btn mr-3"
									>
										Back
									</button>
									<button type="submit" className="btn">
										Save
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditProfile;
