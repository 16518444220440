import React, { useEffect, useState } from "react";
import "../../assets/css/contact.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import service from "../../store/auth/authService";

const Contact = () => {
	const dispatch = useDispatch();
	const { user, statusCode } = useSelector((state) => state.auth);
	const [state2, setState2] = useState({
		name: "",
		email: "",
		phone: "",
		subject: "",
		message: "",
	});

	let { name, email, phone, subject, message } = state2;

	// useEffect(() => {
	// 	setState2(user);
	// }, [user]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const HandleChange = (e) => {
		setState2({ ...state2, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let form_Data = new FormData();
		form_Data.set("name", name);
		form_Data.set("email", email);
		form_Data.set("phone", phone);
		form_Data.set("subject", subject);
		form_Data.set("message", message);
		dispatch(service.Contact(form_Data));
		setTimeout(() => {
			setState2({
				name: "",
				email: "",
				phone: "",
				subject: "",
				message: "",
			});
		}, 2000);
	};
	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Banner Sec */}
			<InnerBanner />
			{/* Banner Sec */}

			{/* Google Map Sec */}
			<section className="map_sec">
				<div className="container">
					<div className="google_map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359294.213879213!2d-113.71754000532059!3d36.247089826313314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1650567615228!5m2!1sen!2s"
							width="100%"
							height="450"
							style={{ border: 0 }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</div>
			</section>
			{/* Google Map Sec */}

			{/* Contact Sec */}
			<section className="contact_sec">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="title">
								<h3>Send Us A Message</h3>
							</div>
							<div className="all_info">
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-phone" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Phone</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>
												<span>Toll Free :</span>
												<a href="tel:0000-0000-0000"> 0000-0000-0000</a>
											</li>
											<li>
												<span>Fax :</span>
												<a href="tel:1232-456-7489"> 1232-456-7489</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-envelope" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Email</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>
												<a href="mailto:mail@example.com">mail@example.com</a>
											</li>
											<li>
												<a href="mailto:mail@example.com">mail@example.com</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Address</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>
												Alienum phaedrum torquatos
												<br />
												nec eu, vis d
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-7">
							<form method="post" onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input
												name="name"
												value={name}
												onChange={HandleChange}
												required
												type="text"
												placeholder="Name"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input
												name="email"
												value={email}
												onChange={HandleChange}
												type="email"
												required
												placeholder="Email"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input
												name="phone"
												value={phone}
												required
												onChange={HandleChange}
												type="tel"
												placeholder="Phone"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input
												type="text"
												name="subject"
												required
												value={subject}
												onChange={HandleChange}
												placeholder="Subject"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<textarea
												name="message"
												value={message}
												required
												onChange={HandleChange}
												placeholder="Your Message"
												className="form-control"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="button-group">
											<button type="submit" className="btn">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* Contact Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Contact;
